import React, { useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import useStyle from '../../hooks/useStyle';
import { useCurrentUserHook } from "../Authentication/useUserHook";

const FilterSlider = ({ component1: Component1, component2:Component2, component3:Component3, component4:Component4, ...props }) => {
  useStyle('filter_dialog');

  const {currentUser, screen} = useCurrentUserHook();
  const sideNavEl = useRef(null);

  useEffect(() => {
    if(props.openSideNav){
      document.addEventListener("click", handleClick, true);
    }
    return () => {
      document.removeEventListener("click", handleClick, true);
    }
  }, [props.openSideNav])

  const handleClick = (e) => {
    if (!sideNavEl.current.contains(e.target)){
      props.setOpenSideNav(false);
    }
  }
  
  return(
    ReactDOM.createPortal(
      <>
        <div id="menu-main" ref={sideNavEl}
          className={`menu filter-box-right menu-box-detached ani-opc-2 ${props.openSideNav ? "menu-active" : "hidden"} `}
          style={{ width: screen.xs ? 260 : 320, zIndex: 400 }}>
          <div className="menu-icons">
          {/* <h5 className="bg-highlight-2 white br-btm-grey center-text p-10"> */}
            <h5 className="bg-highlight-2 white br-btm-grey text-left p-10">
              {props.title ? props.title : "Filters"}
            </h5>
          </div>
          {Component1 && <Component1 {...props} />}
          {Component2 && <Component2 {...props} />}
          {Component3 && <Component3 {...props} />}
          {Component4 && <Component4 {...props} />}

        </div>
      </>, document.body
    )
  )
}

export default FilterSlider;