import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { PortalToggleMenu } from "../GenericPortalPage/Common";
import Cs from "../../../services/CommonService";
import PatientAdditionalInfoForm from "./Form/PatientAdditionalInfoForm";
import GenericModal from "../../Modals/GenericModal";
import useModal from "../../../hooks/useModal";
import { NotesBox } from "../GenericPortalPage/NotesBox";
import UsersRolesPopup from "../../DataShareing/UsersRolesPopup";
import PatientAdditionalInfoService from "./PatientAdditionalInfoService";
import { FormFieldCard } from "../../Common/FormFieldsView";
import ReactTooltip from "react-tooltip";
import { EmptyRowAlert } from '../../Common/TableView';
import FormTemplateList from "../../../constants/FormTemplateList";
import { FontAwesomeBtn } from "../../Common/Button";
import { usePageLocale } from "../../../hooks/useLocale";
import { useCurrentUserHook } from "../../Authentication/useUserHook";
import { Spinner } from "../../Common/Spinner";

const ChildHistoryBlock = (props) => {

  let history = useHistory()
  const {currentUser} = useCurrentUserHook()
  const {labelLocale} = usePageLocale(currentUser.current_locale, 'casePortalPage')

  let { journey, child, formId, componentName, menuOptions, orgBgImageStyle, dataSource } = props

  let {current:scope} = useRef({formProps:null, requestParams:null, formFields:[]});
  
  const [additionalInfo, setAdditionalInfo] = useState({})
  const [loading, setLoading] = useState(false)

  const { isOpen: isInfantActivityFormOpen, toggleModal: toggleInfantActivityModal} = useModal()
  const { isOpen: isNotesOpen, toggleModal: toggleNotesModal } = useModal()
  const { isOpen: isUserRoleOpen, toggleModal: toggleUserRole } = useModal()
  const isMultiChildCase = journey.associated_journey_id && FormTemplateList.mcjMotherForms.indexOf(formId) > -1

  const routeActivityForm = (path, id) => {
    /*Special case for twin triplets mother forms are same*/
    /*Change id to parent case*/
    let journeyCopy = {...journey}
    if(isMultiChildCase){
      journeyCopy.id = journey.associated_journey_id
    }

    scope.formProps = Cs.routeInfantPath(path, {}, journeyCopy, history, null, id);
    //if (path.sid == "instant_baby") {
    scope.formProps.component = PatientAdditionalInfoForm;
    //}
    toggleInfantActivityModal();
  }

  const onFormSubmit = (additional_info) => {
    setAdditionalInfo(additional_info);
    toggleInfantActivityModal();
  }

  const openUserRole = () => {
    toggleUserRole();
  }

  useEffect(() => {
    ReactTooltip.rebuild()
    scope.requestParams = {
      sort_column: "updated_at",
      sort_direction: "desc",
      per_page: 50,
    };

    if ( componentName == "family_details" || componentName == "adolescent" || componentName == "birth_history" || componentName == "household_info" || componentName == "household_details") {
      getAdditionalInfo();
    }
  }, [formId, componentName]);

  let getAdditionalInfo = () => {
    setLoading(true)
    if (componentName == "birth_history") {
      formId = dataSource.child_form //540;
    } else if (componentName == "household_info") {
      formId = dataSource.child_form //498;
    } else if (componentName == "household_details") {
      formId = dataSource.child_form //545;
    } else if(componentName == "family_details" || componentName == "adolescent"){
      formId = dataSource.child_form
    } else {
      formId = 471;
    }
    scope.requestParams.form_id = formId;
    scope.requestParams.form_fields_params = {
      'ids':[formId]
    }
    /*set journey_profile_id to associated_journey_id for mother forms */
    if(isMultiChildCase){
      scope.requestParams.journey_profile_id = journey.associated_journey_id
    }else{
      scope.requestParams.journey_profile_id = journey.id
    }

    PatientAdditionalInfoService.getAll(scope.requestParams).then(({status, data}) => {
      if (status == 200) {
        scope.formFields = data.form_fields[formId] || []
        setAdditionalInfo(data?.additional_info_list[0] || {});
        setLoading(false)
      }
    })
  }

  if(loading)
    return <Spinner/>

  const BirthHistoryTable = () => {
    const data = additionalInfo.data || {}
    const dataSource = additionalInfo.data_source || {}
    return (
      <>
        <div className="f16">
          <ul className="flex aligncenter m-t-15">
            <li className="m-r-20 d-grid-xss">
              <span className="text-lite-gray">Date</span>
              <span className="m-l-8">
                {Cs.formatUpdateDate(additionalInfo?.updated_at)}
              </span>
            </li>
            <li className="m-r-20 d-grid-xss">
              <span className="text-lite-gray">First Pregnancy</span>
              <span className="m-l-8">{dataSource.first_pregnancy?.label}</span>
            </li>
            <li className="m-r-20 d-grid-xss">
              <span className="text-lite-gray">Number Died</span>
              <span className="m-l-8">{dataSource.number_died?.label}</span>
            </li>
          </ul>
          {data.number_died > 0 && 
            <ul className="flex aligncenter m-t-15">
              <li className="m-r-20 d-grid-xss">
                <span className="text-lite-gray">Died First Month</span>
                <span className="m-l-8">
                  {dataSource.died_first_month?.label}
                </span>
              </li>
              <li className="m-r-20 d-grid-xss">
                <span className="text-lite-gray">Died First Year</span>
                <span className="m-l-8">
                  {dataSource.died_first_year?.label}
                </span>
              </li>
            </ul>
          }
        </div>
      </>
    )
  }

  const HouseHoldInfo = () => { 
    const data = additionalInfo.data || {}
    const dataSource = additionalInfo.data_source || {}
    return (
      <>
        <div className="f16">
          <ul className="flex aligncenter m-t-15">
            <li className="m-r-20 d-grid-xss">
              <span className="text-lite-gray">Date</span>
              {Cs.formatUpdateDate(additionalInfo?.updated_at)}
            </li>
            <li className="m-r-20 d-grid-xss">
              <span className="text-lite-gray">Family Income Level</span>
              {dataSource.income_category?.label}
            </li>
            <li className="m-r-20 d-grid-xss">
              <span className="text-lite-gray">Social category</span>
              {dataSource.social_category?.label}
            </li>
            <li className="m-r-20 d-grid-xss">
              <span className="m-l-15 display-inline white"
                onClick={() =>routeActivityForm(dataSource, additionalInfo.id)}>
                  <i className={additionalInfo.id?'fas fa-edit':'fas fa-plus'} />
              </span>
            </li>
          </ul>
          <ul className="flex aligncenter m-t-15">
            <li className="m-r-20 d-grid-xss">
              <span className="text-lite-gray">Wage labour?</span>
              {dataSource.is_wage_labour?.label}
            </li>
            <li className="m-r-20 d-grid-xss">
              <span className="text-lite-gray">Mother_occupation</span>
              {data.occupation}
            </li>
          </ul>
        </div>   
      </>
    )
  }

  const DetailsInfo = () => {
    const data = additionalInfo.data || {}
    const dataSource = additionalInfo.data_source || {}
    return (
      <>
        <div className="f16">
          <ul className="flex aligncenter m-t-15">
            <li className="m-r-20 d-grid-xss">
              <span className="text-lite-gray">Date</span>
              {Cs.formatUpdateDate(additionalInfo?.updated_at)}
            </li>
            <li className="m-r-20 d-grid-xss">
              <span className="text-lite-gray">Family Income Level</span>
              {dataSource.family_income_monthly?.label}
            </li>
            <li className="m-r-20 d-grid-xss">
              <span className="text-lite-gray">Social category</span>
              {dataSource.social_social_category_category?.label}
            </li>
          </ul>
          <ul className="flex aligncenter m-t-15">
            <li className="m-r-20 d-grid-xss">
              <span className="text-lite-gray">Wage labour?</span>
              {dataSource.mother_wage_labour?.label}
            </li>
            <li className="m-r-20 d-grid-xss">
              <span className="text-lite-gray">Mother_occupation</span>
              {data.mother_occupation}
            </li>
          </ul>
        </div>     
      </>
    ) 
  }

  const AdditionalInfo = () => {
    return (
      <>
        <div className="font-14">
          <span className="bold"> Last Updated</span>
            {Cs.formatUpdateDate(additionalInfo?.updated_at)}
            <span data-tip="Edit additional household info" className="m-l-15 display-inline white"
              onClick={(e) => routeActivityForm(dataSource, additionalInfo.id)}>
            <i className="fas fa-edit lred" />
          </span>
        </div>

        <div className="table card-table p-5">
          <FormFieldCard formFields={scope.formFields} 
            data={additionalInfo.data} 
            dataSource={additionalInfo.data_source}/>
        </div>   
      </>
    ) 
  }

  const PriorServices = () => {
    return (
      <tr>
        <td className="text-left p-0">
          <span className="font-20">
            {journey.record_id} -{" "}
            {" " + journey.data?.mother_name || journey.data?.patient_name}
          </span>
          <div className="font-14">
            <i className="fas fa-phone white m-r-10 p-5" />
            {journey.data?.cell_number_formatted || "Mobile Not Provided"}
          </div>
          <div className="font-14">
            <div>
              <span>prior_services </span> - 
              <span> {additionalInfo?.data?.mil_home_details || 'No data'}</span>
            </div>
            <div>
              <span>Planned delivery location </span> - 
              <span> {additionalInfo?.data_source?.planned_delivery_location?.label || 'No data'}</span>
            </div>
            <div>
              <span>Migration address </span> - 
              <span> {additionalInfo?.data?.migration_address || 'No data'}</span>
            </div>
          </div>
          <div className="font-14">
            <span className="bold font-18"> Last Updated</span>
            {Cs.formatUpdateDate(additionalInfo?.updated_at)}
            <span
              data-tip="Edit prior services"
              className="m-l-15 display-inline white"
              onClick={(e) =>
                routeActivityForm(
                  dataSource,
                  additionalInfo.id
                )
              }
            >
              <i className={additionalInfo.id?'fas fa-edit':'fas fa-plus'} />
            </span>
          </div>
        </td>
      </tr>
    ) 
  }

  let components = {
    birth_history: BirthHistoryTable,
    household_info: HouseHoldInfo,
    household_details: DetailsInfo,
    prior_services: PriorServices,
    addnal_info: AdditionalInfo,
  }

  const TagName = components[componentName];

  return (
    <>
      <div className="flex coldir fulwid p10">
        <div className="flex alignstart justspacebetween">
          <div className="h180-scroll">
            {additionalInfo.id?
              <TagName {...props}/>
              :
              <div className="f16 wk-center">
                <EmptyRowAlert/>
                <FontAwesomeBtn id="add" tooltip="Add details" 
                  iconCss="fa fas fa-plus" isResponsive={false} name={labelLocale(3)}
                  css="m-t-5" colorCss="btn-green"
                  onSelect={()=>routeActivityForm(dataSource, additionalInfo.id)}>
                </FontAwesomeBtn>
              </div>
            }
          </div>

          <div className="flex aligncenter">
            <span data-tip="Add/Edit details"
              className="icon-black-cirle brd-30 flex aligncenter justifycenter" 
              onClick={() => routeActivityForm(dataSource, additionalInfo.id)}>
                <i className={additionalInfo.id?'fas fa-edit':'fas fa-plus'} />
            </span>

            <PortalToggleMenu
              journey={journey} 
              menuOptions={menuOptions} 
              journeyType="infant_journey"
              menuDsId="3530"
              permissionKey="infant_portal"/>
          </div>
        </div>
      </div>
      
      {isInfantActivityFormOpen && (
        <GenericModal
          toggleModal={toggleInfantActivityModal}
          isPopupMode="true"
          onFormSubmit={onFormSubmit}
          closeConfirmPopup={true} {...scope.formProps}
        />
      )}

      {isNotesOpen && (
        <GenericModal
          title="Notes"
          component={NotesBox}
          toggleModal={toggleNotesModal}
          journeyProfileId={journey.id}
          noteableId={journey.id}
          currentUser={currentUser}
        />
      )}

      {isUserRoleOpen && (
        <GenericModal
          component={UsersRolesPopup}
          isOpen={isUserRoleOpen}
          toggleModal={toggleUserRole}
          itemType="journey_profile"
          itemId={journey.id}
          showRoles="true"
          showOrgMembers="true"
          title="Assign this case to Roles and/or Users"
          medicalFacilityId={journey.medical_facility_id}
        />
      )}
    </>
  )
}

export default ChildHistoryBlock;