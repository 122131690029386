import React, { useState } from 'react';

function ErrorsList({labelLocale, errors, formFn, setActiveKey}){
  
  const [count, setCount] = useState(0);

  formFn.refreshErrorsList = () =>{
    setCount(value => ++value)
  }

  let goToErrorField = (error) =>{
    setActiveKey(`${error.tab_index}`); 
    setTimeout(function() {
      try{
        let ele = document.getElementById(`label_${error.id}`)
        ele.scrollIntoView();
        ele.style.border = '1px solid red'
      }catch(e){

      }
    }, 250); 
  }

  if(!errors.isSubmitted)return null;

  return(
    <div className="clearfix">
      <div className="flex gap-10 aligncenter error-field">
        <span className="fas fa-exclamation-triangle red"/>
        <span id="error_list" className="fw-700 f16">
          {labelLocale(22)}
        </span>
      </div>
      {Object.keys(errors).map((key, i) => (
        errors[key].invalid ? 
          <div className="error-field col-xs-12 col-sm-6" key={i} onClick={e=>goToErrorField(errors[key])}>
            <span>{errors[key].label}</span>
          </div>
        :
        null
      ))}
    </div>
  )

}

export default ErrorsList;