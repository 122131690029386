import {useContext} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import CheckAccess from "../Roles/CheckAccess";

const useCurrentUserHook = () => {
	const { state: {screen, user: currentUser}, dispatch, isAuthenticated, localeDispatch} = useContext(AuthContext);

	const hasDepartments = () => {
		return currentUser.current_organization.data_source?.organization?.label === "Government Unit";
	}

	const getCurrentOrgName = () => {
		return currentUser.current_organization.data?.name;
	}

	const getCurrentOrgData = () => {
		return currentUser.current_organization.data;
	}

	const getOrgNameById = (orgId) =>{
		return currentUser.organizations.find(o => o.id === orgId)?.name;
	}

	const getCurrentSubscription = () =>{
		return currentUser.current_subscription;
	}

	const getCurrentMfIds = () =>{
	    const org = currentUser.organizations.find(o => o.id === currentUser.current_organization_id);
	    return org ? org.medical_facilities?.map(f => f.id) : null;
  	}

  	const isDevUser = () => CheckAccess.isDevUser(currentUser.email);

  	const isKcorpOrg = () => currentUser.current_organization_id === '52acd869-1dcb-4f11-a337-53ce7eb2d994';

	return{
		screen,
		currentUser,
		dispatch,
		isAuthenticated,
		localeDispatch,
		hasDepartments,
		getCurrentOrgName,
		getOrgNameById,
		getCurrentSubscription,
		getCurrentMfIds,
		getCurrentOrgData,
		isDevUser,
		isKcorpOrg	
	}
}

export {useCurrentUserHook};