import { useState } from 'react';
import Cs from "../../../../../services/CommonService";
import { getLocale, getLocaleData } from '../../../../FormBuilder/FieldLabel';

const useActivityHook = (childProp={}) => {
  const [child, setChild] = useState(childProp)

  const ageFormat = (id, scheduled_date, data) =>{
    const visit_date = id?data.visit_date_formatted_date:scheduled_date
    if(visit_date && child?.data){
      return Cs.ageDWMY(child.data.dob_baby_formatted_date, visit_date);
    }
  }

  const getBgColorByZscore = (z_score, label) =>{
    try{
      z_score = parseFloat(z_score)
      if(z_score <= -3){
        const style = {'backgroundColor': '#FFA191' ,'color': '#A80000'}
        return {
          'r1': style,
          'r2': style,
          'r4': getBgColorByZscoreLable(label)
        }
      }else if(z_score <= -2){
        const style = {'backgroundColor' : '#f4b80c','color': '#fffff'}
        return {
          'r1': style,
          'r2': style,
          'r4': getBgColorByZscoreLable(label)
        }
      }else if(z_score <= -1){
        const style = {'backgroundColor': '#FCFC7A' ,'color':'#918B01'}
        return {
          'r1': style,
          'r2': style,
          'r4': getBgColorByZscoreLable(label)
        }
      }else{
        const style = {'backgroundColor': '#9AFFA9' ,'color': '#00AB8E'}
        
        return {
          'r1': style,
          'r2': style,
          'r4': style
        }
      }
    }catch(e){
      console.error(e.message)
      return {}
    }
  }

  const getBgColorByZscoreLable = (zlabel) =>{
    switch (zlabel) {
      case 'SUW':
      case 'SS':
      case 'Severe Stunting':
      case 'SAM':
        return {'backgroundColor': '#FFA191' ,'color': '#A80000'}
        break;
      case 'MUW':
      case 'Moderate Stunting':
      case 'MS':
      case 'MAM':
        return {'backgroundColor' : '#f4b80c','color': '#fffff'}
        break;
      case 'Mild':
        return {'backgroundColor': '#FCFC7A' ,'color':'#918B01'}
        
        break;
      default:
        return {'backgroundColor': '#9AFFA9' ,'color':'#00AB8E'}
    }
  }

  return {
    child, 
    setChild,
    ageFormat,
    getBgColorByZscore,
    getBgColorByZscoreLable
  }
}

const useAccessBfHook = (currentLocale, accessBFTableLocale) =>{
  
  const setAccessCfReports = (rows, formFields, babyAgeMonth) =>{
    for (const r of rows) {
      r.cfReports = groupAccessCFReport(r, formFields, babyAgeMonth);
    }
    return rows;
  }

  const groupAccessCFReport = (row, formFields, babyAgeMonth) =>{
    const report = {'pos':[], 'neg':[]};
    if(row.data){
      for (const field of formFields) {
        if(field.data_source_id){
          const d = row.data[field.client_id];
          const da = row.data[field.client_id+'_array'];
          const ds = row.data_source[field.client_id];
          if(Array.isArray(da)){
            for (const o of field.data_source.options) {
              //check baby age
              if(o.age_min && o.age_max && babyAgeMonth){ 
                if(!(o.age_min >= babyAgeMonth && o.age_max <= babyAgeMonth)){
                  continue; //next iteration
                }
              }

              if(da.includes(o.value)){ //selected
                report.pos.push({
                  'fieldlabel': getLocale(o, currentLocale, 'label'), 
                  'clientId': field.client_id,
                  'className': o.rating == 1 ? 'bg-lgreen' : 'bg-lred'
                })
              }else if(o.rating == 1){ //not selected
                report.neg.push({
                  'fieldlabel': getLocale(o, currentLocale, 'label'), 
                  'clientId': field.client_id,
                  'className': 'bg-grey-4'
                })
              } 
            }  
          }else if(ds?.rating != null && field.short_label){ 
            if(ds.rating == 1){
              report.pos.push({
                'fieldlabel': getLocale(field, currentLocale, 'short_label'), 
                'clientId': field.client_id,
                'className': 'bg-lgreen'
              })
            }else if(ds.rating == -1){ 
              report.pos.push({
                'fieldlabel': getLocale(field, currentLocale, 'short_label'), 
                'clientId': field.client_id,
                'className': 'bg-lred'
              })
            } 
          }
        }
      }
    }
    return report;
  }

  const groupAccessBFReport = (formFields, dataSource) =>{
    const accessBfReport = {'pos':[], 'neg':[]};
    if(formFields && dataSource){
      for (const f of formFields) {
        const ds = dataSource[f.client_id];
        if(Array.isArray(ds)){
          const count = ds.reduce((acc, d) => {
            return acc + d.data.rating;
          }, 0);

          accessBfReport[checkRating(count)].push({
            'fieldlabel': getLocale(f, currentLocale, 'label'), 
            'clientId': f.client_id,
            'count': count
          })
        }else if(ds?.value != null){
          accessBfReport[checkRating(ds.rating)].push({
            'fieldlabel': getLocale(f, currentLocale, 'label'), 
            'clientId': f.client_id, 
            'count': 1
          })
        }
      }
    }
    return accessBfReport;
  }

  const checkRating = (rating) =>{
    if(rating <= 0){
      return 'neg';
    }

    return 'pos';
  }

  const checkAlertMessage = (activity) =>{
    if(activity.id){
     return '';
    }else if(Cs.compareDate(activity.scheduled_date, null, 'days') >= 0){
      // 'A visit to take new measurements is scheduled for this date'
      return activity.notification_text || accessBFTableLocale(7);
    }else{
      return accessBFTableLocale(18);
    }
  }

  return {
    setAccessCfReports,
    groupAccessBFReport,
    checkAlertMessage
  }
}

export {useActivityHook, useAccessBfHook};