import env from '../../../../env';
import axios from 'axios';

let url = env.infant_backend

class ActivityService {
  
  constructor() {
    this.postHeader = {
      headers: {
        'client-cache':true
      }
    }
    this.putHeader = {
      headers: {
        'client-cache':false
      }
    }
  }

  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}activities`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(req){
    return new Promise((resolve, reject) => {
      axios.post(`${url}activities`, req, this.postHeader).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  getById(req){
    return new Promise((resolve, reject) => {
      axios.get(url+'activities/'+(req.id || null), { params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  async getByRecordDate(req){
    const res = await axios.get(url+'activity/find_by_record_date', {params: req});
    return res;
  }
  

  update(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${url}activities/${req.id}`, req, this.putHeader).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(id, req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}activities/${id}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  report(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}reports/activities`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  suggestions(req){
    return new Promise((resolve, reject) => {
      axios.post(`${url}suggestions`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}

export default new ActivityService();