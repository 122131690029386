import React, { useEffect, useState } from 'react'
import useStyle from '../../hooks/useStyle'
import TrainingProgramsService from './TrainingProgramsService'
import { PlannerVideoPlayer } from "../Common/VideoPlayer"
import useModal from "../../hooks/useModal"
import { ImagePlayList } from "../Common/Image"
import { ChatGrayIcon, CheckListGrayIcon, LinkFontAwesomeIcon } from '../Common/ImageIcons'
import ChecklistsModal from '../Modals/ChecklistsModal'
import CommentsModal from '../Modals/CommentsModal'
import { useReadStatus } from '../ReadStatus/useReadStatusHook'
import TraineeReferenceForm from './TrainingTopics/TraineeReferenceForm'
import ParagraphQuestionForm from './Assessments/Form/ParagraphQuestionForm'
import querystringify from "querystringify"
import { DivRichTextView, ParaRichTextView, SpanRichTextView } from '../Common/RichTextView'
import { useCurrentUserHook } from '../Authentication/useUserHook'

const TraineeContentList = (props) => {
  useStyle('/css/charts_layout_filter.css', true)

  const params = props.match.params;
  let queryParam = querystringify.parse(props.location?.search);

  const {currentUser} = useCurrentUserHook()
  const [trainingProgram, setTrainingProgram] = useState({})
  const [selectedContent, setSelectedContent] = useState()
  const [activeIndex, setActiveIndex] = useState()
  const [selectedItem, setSelectedItem] = useState()
  const [showMoreOverview, setShowMore] = useState(false)

  const { isOpen:isVideoPlayerOpen, toggleModal:toggleVideoPlayer } = useModal()
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal } = useModal()
  const {updateReadStatus} = useReadStatus()

  useEffect(() => {
    getAll()
  }, [currentUser.id])

  let getAll = () => {
    TrainingProgramsService.getTraineeContent({
      'id':params.training_program_id,
      'org_id':queryParam.org_id
    }).then((res) => {
      if (res.status == 200) {
        setTrainingProgram(res.data.training_program)
      }
    })
  }

  /*const onContentSelect = (content) =>{
    if(content.data?.video){
      setSelectedContent(content)
      toggleVideoPlayer()
    }
  }*/

  const openSession = (idx) =>{
    const session = trainingProgram.training_sections[idx]
    if(session.is_viewed == 0)
      updateReadStatus(idx, {'item_id':session.id, 'item_type':'training_section'}, setReadStatus)
    setActiveIndex(activeIndex === idx?null:idx)
  }

  const setReadStatus = (idx) =>{
    trainingProgram.training_sections[idx].is_viewed = 1
    setTrainingProgram({...trainingProgram})
  } 

  const addComment = (item) => {
    setSelectedItem(item)
    toggleCommentModal()
  }

  const addChecklist = (item) => {
    setSelectedItem(item)
    toggleChecklistModal()
  }

  return(
    <>
      <div id="ct">
        <div className="app-content">
          <div className="app-header flex justspacebetween card bg-white brd-10 m-t-20-xs">
            <div className="flex justspacebetween aligncenter p20 wid-100">
              <div className="flex coldir alignstart justspacebetween">
                <div className="app-title m-r-20">{trainingProgram.data?.program_name || trainingProgram.data?.host}</div>
              </div>
            </div>
          </div>

          {trainingProgram.data?.overview ?
            <div className="app-content-section m-t-5">
              <div className="card bg-white brd-10 p10 m-b-10"
                onClick={()=>setShowMore(!showMoreOverview)}>
                <div className="flex justspacebetween m-r-20 pointer">
                  <div className="m-t-10 m-l-10 w-90p">
                    <span className="f17 fw500">Overview of the Training Session</span>
                    {showMoreOverview?
                      <SpanRichTextView html={trainingProgram.data?.overview}/>
                      :null
                    }
                  </div>
                  <div className="text-center">
                    <span className={showMoreOverview?'fas fa-angle-down f16':'fas fa-angle-up f16'}/>
                  </div>
                </div>
              </div>
            </div>
            :
            null
          }

          <div className="app-content-section m-t-20">
            <SessionsList 
              list={trainingProgram.training_sections} 
              currentUserId={currentUser?.id}
              addComment={addComment}
              addChecklist={addChecklist}
              activeIndex={activeIndex}
              openSession={openSession}
              updateReadStatus={updateReadStatus}
              setTrainingProgram={setTrainingProgram}/>
          </div>
        </div>
      </div>

      {isCommentOpen && 
        <CommentsModal isOpen={isCommentOpen} 
          toggleModal={toggleCommentModal} item={selectedItem} 
          type="training_content"/>
      }
      
      {isChecklistOpen && 
        <ChecklistsModal isOpen={isChecklistOpen} 
          toggleModal={toggleChecklistModal} item={selectedItem} 
          type="training_content"/>
      }
    </>
  ) 
}

const SessionsList = ({list=[], currentUserId, openSession, activeIndex, addComment, addChecklist, updateReadStatus, setTrainingProgram}) => list ? list.map((s, i)=>
  <Session key={i} {...{s, currentUserId, openSession, activeIndex, i, addComment, addChecklist, updateReadStatus, setTrainingProgram}}/>  
):null

const Session = ({s, currentUserId, openSession, activeIndex, i, addComment, addChecklist, updateReadStatus}) =>{
  const [contentIndex, setContentIndex] = useState()
  let [contents, setContents] = useState(s.training_contents)

  const openContent = (idx) =>{
    const content = contents[idx]
    if(!content.is_viewed)
      updateReadStatus(idx, {'item_id':content.id, 'item_type':'training_content'}, setReadStatus)
    setContentIndex(contentIndex === idx?null:idx)
  }

  const setReadStatus = (idx) =>{
    contents[idx].is_viewed = true
    setContents([...contents])
  } 

  return(
    <div className="card bg-white brd-10 p10 m-b-10">
      <div className="flex justspacebetween m-r-20 pointer" onClick={()=>openSession(i)}>
        <div className="w-90p">
          <span className="badge-2 white" style={{background: '#80868b'}}>{i+1}</span>
          <span className="f17 m-l-10 fw500">{s?.data?.title_session}</span>
        </div>
        <div className="flex coldir text-center">
          <span className="badge-2 m-r-10">{s.training_contents?.length} Items / {s.view_count} Views  </span>
        </div>
        <div className={activeIndex === i?'fas fa-angle-down f16':'fas fa-angle-up f16'}/>
      </div>
      {activeIndex === i ?
        <>
          <DivRichTextView className="m-t-10" html={s.data?.instructions}/>

          <Contents list={contents} openContent={openContent} 
            contentIndex={contentIndex} addComment={addComment} 
            addChecklist={addChecklist} currentUserId={currentUserId}/>
        </>
        :
        null
      }
    </div>
  )
}

const Contents = ({list=[], currentUserId, contentIndex, openContent, addComment, addChecklist}) => list ? list.map((s, i)=>{
  const isActive = contentIndex === i
  let formProps = null  
  if(s.data?.training_form_id){
    formProps = {
      'isPopupMode':true,
      'match':{
        'params':{
          'formId':s.data?.training_form_id
        }
      }
    }
  }

  const downloadVedio = (s) =>{
    window.open(s.data.high_res_video_url);
    /*
    try {
      const videoUrl = s.data.high_res_video_url;
      const videoRequest = new Request(videoUrl);
      fetch(videoRequest)
        .then(() => {
          const link = document.createElement('a');
          link.href = videoUrl;
          link.setAttribute('download', 'waterfall.mp4');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    } catch (error) {
      console.error(error);
    }
    */
  }

  return(
    <div key={i} className={`br-grey-hov ${isActive?'br-black':''} m-t-14`}>
      <div onClick={()=>openContent(i)} className={`flex coldir-xs justspacebetween pointer ${isActive?'br-black':''}`}>
        <div className="font-16 m-t-4">
          <span className={`ml-30-md far ${isActive?'fa-caret-square-down':'fa-caret-square-right'} m-r-10`}/>
          {s.data.title}
        </div>
        <div className="m-t-5 m-r-5">
          <span className="pull-right">{s.view_count} Views / {s.comment_count} Comments</span>
        </div>
      </div>
      {isActive ?
        <>
          <div className="flex justspacebetween aligncenter pre-wrap m-t-5">
            <ParaRichTextView className="p-l-15" html={s.data?.description}></ParaRichTextView>
            {currentUserId ?
              <div className="flex justspacebetween">
                {s.data.high_res_video_url ? 
                  <span className="m-r-25">
                    <LinkFontAwesomeIcon parentCss="table-icon-lgrey table-icon-sm" 
                      tooltip="Download" onSelect={()=>downloadVedio(s)} 
                      iconCss="fa-light fa-arrow-down-to-line"/>
                  </span>
                  : null
                }
                <span className="m-r-25">
                  <CheckListGrayIcon count={s.comment_count} onSelect={() => addChecklist(s)}/>
                </span>
                <span className="m-r-25">
                  <ChatGrayIcon onSelect={() => addComment(s)}/>
                </span>
              </div>
              :
              null
            }
          </div>
          <div className="text-center border-dashed-sm">
            <ImagePlayList list={s.data?.upload} styleName="img-responsive"/>
          </div>
          {s?.data.low_res_video_url ?
            <PlannerVideoPlayer url={s?.data.low_res_video_url} playInline={true}/>
            :
            null
          }
          <ParagraphQuestionForm trainingContentId={s.id}/>
          {formProps && 
            <TraineeReferenceForm {...formProps}/>
          }
        </>
        :
        null
      }
    </div>
  )
}):null

export default TraineeContentList;