import React, { useEffect, useState, useMemo, useRef, useContext } from "react";
import moment from '../../../../../../node_modules/moment/min/moment.min.js';
import DynamicForm from "../../../../FormBuilder/DynamicForm";
import FormTemplateId from "../../../../../constants/FormTemplateList";
import FormContainer from '../../../../../components/FormBuilder/FormContainer';
import GeoLocation from "../../../../../components/Common/GeoLocation";
import Cs from "../../../../../services/CommonService";
import CheckAccess from '../../../../Roles/CheckAccess';
import FormWizard from "../../../../FormBuilder/FormWizard";
import { useCurrentUserHook } from "../../../../Authentication/useUserHook.js";
import querystringify from "querystringify";
import ActivityService from "../ActivityService";
import { NotificationPopup } from "../../../../Common/NotificationPopup";
import { JourneyContext } from '../../../List/JourneyContext';

function ActivityForm(props) {
  let { current: scope } = useRef({ formFn: {}});
  const {currentUser} = useCurrentUserHook();

  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  const [lastActivity, setLastActivity] = useState();
  const [notRequiredToFillForm, setNotRequiredToFillForm] = useState(false);

  let {journeyState:{activeJourney}} = useContext(JourneyContext);

  const params = props.match.params;
  const queryParam = querystringify.parse(props.location.search);
  const caseInfo =  props.location.state;
  const formReportType = (queryParam.sid === "assess_bf" && caseInfo.weight_zscore > -1)?"assess_bf_favorable_count":null;
  const isMCJMeasureGrowthForm = queryParam.sid === "growth_measurement";

  const permission = useMemo(() => 
    CheckAccess.getMemberPermissionBySid(
      currentUser, 
      'infant_process', 
      queryParam.label
    ), [currentUser.id]
  )

  /*TODO: Move API to Router to load data before view*/
  /*last_activity -> get last submission*/
  /*parent_activity -> get previous submission from current activity*/
  const get = (fields) => {
    const req = {
      'id': params.id,
      'report_type': formReportType,
      'journey_profile_id': params.journey_profile_id,
      'activity_form_id':queryParam.activity_form_id,
      'fields':fields
    }
    ActivityService.getById(req).then((res) => {
      if (res.status === 200) {
        scope.activity = {...res.data.activity, ...scope.activity.geo_data};
        scope.data = {...scope.data, ...scope.activity.data};
        scope.formFn.ds = scope.activity.data_source;
        setLastActivity(res.data.last_activity);
        setAssessBfFavorable(res.data.reports) ;
        if(scope.activity?.id)
          scope.formFn.editPermission = CheckAccess.hasMcjActivityEditAccess(currentUser, scope.activity, queryParam.sid);
        setFormRendered(true);
      }
    }).catch(function (res) {
      setFormRendered(true);
    })
  }

  useEffect(() => {
    scope.formFn = {
      'form_type': 'infant.activity',
      'journey_profile_id': params.journey_profile_id
    };
    scope.activity = {};
    scope.data = {};
    scope.formFn.ds = {};
    setChildInfo();
    setFormMode();
  }, [])

  const setFormMode = () =>{
    if (params.id) {
      get(isMCJMeasureGrowthForm?['parent_activity']:null);
      scope.formMode = "edit-form-submissions";
    } else if (!isFormRendered) {
      scope.formMode = "create-form-submissions";
      if(formReportType || isMCJMeasureGrowthForm){
        if(params.journey_profile_id){
          get(['last_activity']);
        }else{
          setFormRendered(true);
        }
      }else{
        setFormRendered(true);
      }
    }
  }

  const setChildInfo = () =>{
    if(caseInfo){
      //TODO Check this temp info needed
      let temp = {};
      if(caseInfo.baby_age_dwmy) temp.baby_age_dwmy = caseInfo.baby_age_dwmy;
      if(caseInfo.baby_age) temp.baby_age = caseInfo.baby_age;
      if(caseInfo.baby_age_days) temp.baby_age_days = caseInfo.baby_age_days;
      if(caseInfo.child_fk_id) temp.child_fk_id = caseInfo.child_fk_id;
      if(caseInfo.dob_baby_formatted_date) temp.dob_baby_formatted_date = caseInfo.dob_baby_formatted_date;
      if(caseInfo.id_mother) temp.id_mother = caseInfo.id_mother;
      if(caseInfo.record_date){
        temp.record_date = caseInfo.record_date;
        temp.record_date_formatted_date = caseInfo.record_date_formatted_date;
      }

      if(queryParam.label === 'prenatal_care' && activeJourney?.data){
        temp.pregnancy_stage = activeJourney.data.pregnancy_stage;
      }

      scope.formFn.foreignData = {
        ...temp,
        "lmp_formatted_date": caseInfo.lmp_formatted_date
      }
      Object.assign(scope.data, temp); 
    }
  }

  const setAssessBfFavorable = (reports=[]) =>{
    scope.formFn.assess_bf_favorable = Cs.listToObj(reports, 'client_id');
  }

  const showAssessBfField = (field) =>{
    //fields gte max_select_count from data source not shown
    return scope.formFn.assess_bf_favorable && scope.formFn.assess_bf_favorable[field.client_id];  
  }

  const checkBfFormSubmitted = (formFields=[]) =>{
    if(formReportType === 'assess_bf_favorable_count'){
      let fieldsNotSelected = [];
      for(const cf of formFields){
        const fs = cf.child_template_fields.filter(f => showAssessBfField(f) == null);
        fieldsNotSelected = fieldsNotSelected.concat(fs);
      }
      // <= 1 for record_date field inclusive
      if(fieldsNotSelected.length <= 1) setNotRequiredToFillForm(true);
    }
  }

  const resetError = (error) =>{
    error.invalid = false;
    error.err_msg = null;
  }

  scope.formFn.on_select_height_baby = (height, error) =>{
    try{
      const lastHeightMeasurement = lastActivity.data_source ? lastActivity.data_source.height_baby.label : caseInfo?.birth_height;
      if(height.label != null && parseFloat(height.label) < parseFloat(lastHeightMeasurement)){      
        /*Remove error msg if err_msg already set*/
        if(scope.formFn.isHeightErrorAlreadySet){
          resetError(error);
          scope.data.is_invalid_height = true;
        }else{
          error.invalid = true;
          error.err_msg = `The length you have just entered may be incorrect based on the data you previously entered.
          Please check and re-enter the length.`;
          scope.formFn.isHeightErrorAlreadySet = true;
        }
      }else{
        resetError(error);
      }
    }catch(e){
      console.error(e.message);
    }
  }

  scope.formFn.set_min_visit_date = (attributes) => setMinDate(attributes);
  scope.formFn.set_min_record_date = (attributes) => setMinDate(attributes);
  
  const setMinDate = (attributes) =>{
    if(
      ['growth_measurement', 'assess_bf', 'assess_cf', 'immunization'].includes(queryParam.label) 
      && caseInfo?.dob_baby_formatted_date
    ){
      attributes.minDate = moment(caseInfo.dob_baby_formatted_date).toDate();
    }else if(
      ['prenatal_care', 'periodic_check', 'protein_intake'].includes(queryParam.label)
      && caseInfo?.case_record_date){
      attributes.minDate = moment(caseInfo.case_record_date).toDate();
    }
  }

  scope.formFn.on_select_visit_date = (dateObj, recordDate) =>{
    try{
      if(lastActivity?.data?.visit_date_formatted_date == recordDate){
        props.openDupActivityAlert(lastActivity);
        /*const height = lastActivity.data_source?.baby_weight?.label;
        const weight = lastActivity.data_source?.height_baby?.label;
        window.alert("Check growth done: "+ recordDate +"\n"+ "Weight: " + weight +"\n"+ "Height: "+ height);*/
      }else{
        props.getByRecordDate(recordDate);
      }
    }catch(e){
      
    }
  }

  scope.formFn.on_select_record_date = (dateObj, assessmentDate) =>{
    /*if(queryParam.label === "prenatal_care" && caseInfo.lmp_formatted_date){
      const diffVal = Cs.compareDate(assessmentDate, caseInfo.lmp_formatted_date, "days");  
      //window.alert(diffVal / 28);
      scope.data.gestational_week = diffVal;
    }*/

    scope.data.baby_age_dwmy = Cs.ageDWMY(caseInfo.dob_baby_formatted_date, assessmentDate);

    if(queryParam.label === "assess_cf"){ 
      scope.data.baby_age_days = Cs.getAge(caseInfo.dob_baby_formatted_date, assessmentDate, null, null, 'days');
    }
  }

  const create = (activity) => {
    activity.organization_id = currentUser.current_organization_id;
    activity.created_by = currentUser.id;
    activity.updated_by = currentUser.id;
    activity.journey_profile_id = params.journey_profile_id;
    activity.activity_form_id = parseInt(queryParam.activity_form_id) || 440;
    activity.activity_type = queryParam.label || "Activity";
    activity.parent_activity_id = lastActivity?.id;
    activity.schedule_id = params.schedule_id;
    activity.member_role_id = currentUser.current_member?.role_record_id;
    activity.data_collected_by = queryParam.data_collected_by;
    activity.organization_member_id = currentUser.current_member?.id;
    ActivityService.create(activity).then((res) => {
      if (res.status === 201) {
        onSubmitPageRedirect(res.data.activity);
      }
    }).catch((res) => {
      onSubmitPageRedirect();
    })
  }

  const update = (activity) => {
    activity.updated_by = currentUser.id;
    //activity.member_role_id = currentUser.current_member?.role_record_id;
    ActivityService.update(activity).then((res) => {
      if (res.status === 204) {
        onSubmitPageRedirect();
      }
    }).catch((res) => {

    })
  }

  const onSubmitPageRedirect = (activity) => {
    if(props.isPopupMode){
      props.onFormSubmit(activity);
    }else{
      props.history.goBack();
    }
  }

  if(notRequiredToFillForm){
    return(
      <div className="m-t-20">
        <NotificationPopup title="Alert" successLabel="Close"
          message="You do not have to fill and submit the Check BF form."
          onSuccess={()=>onSubmitPageRedirect()}  
          iconClass="fas fa fa-check-circle green"/>
      </div>
    )
  }else if(isFormRendered && queryParam.is_form_wizard){
    return(
      <FormWizard 
        formId={queryParam.activity_form_id || FormTemplateId.HealthActivityForm} 
        form={scope.activity} 
        data={scope.data} 
        formFn={scope.formFn} 
        onCreate={create} 
        onUpdate={update} 
        onCancel={onSubmitPageRedirect}
        showFieldMenu={true}
        isPopupMode={true}
        formMode={scope.formMode}
        permission={permission}
        onBeforeFormRender={checkBfFormSubmitted}
        fieldShowHideFn={formReportType=='assess_bf_favorable_count' && showAssessBfField}/>
    )
  }else if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={scope.formMode}
          formId={queryParam.activity_form_id || FormTemplateId.HealthActivityForm}
          form={scope.activity}
          data={scope.data}
          formFn={scope.formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          fieldShowHideFn={formReportType=='assess_bf_favorable_count' && showAssessBfField}
          isPopupMode={props.isPopupMode}
          isReviewEnabled={queryParam.show_review}
          permission={permission}/>
        <GeoLocation geoData={scope.activity}/>
      </FormContainer>
    )
  }

  return null;
}

export default ActivityForm;