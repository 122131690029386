import React, { useState, useEffect, useContext } from "react"
import Cs from "../../../services/CommonService"
import CaseReportService from './CaseReportService'
import useModal from "../../../hooks/useModal"
import GenericModal from '../../Modals/GenericModal'
import { CueTip } from "../../Common/CueTooltip"
import PaginationSM from '../../Common/PaginationSM'
import { EmptyRowAlert } from '../../Common/TableView'
import { useSearch } from '../../../hooks/useSearch'
import CaseReportForm from './CaseReportForm'
import ConfirmMenu from "../../Modals/ConfirmMenu"
import useSort from '../../../hooks/useSorting'
import { Table, THead, TH, TR, TD, TBody } from "../../Common/TableView"
import SortDirection from '../../../components/Common/SortDirection'
import { LinkDeleteIcon } from "../../Common/ImageIcons"
import AddCaseAssessmentIco from "../CaseAssessments/AddCaseAssessmentIco"
import CaseReportVerificationService from './CaseReportVerificationService'
import { CheckBox } from "../../Common/FormInput"
import { LocalizeContext } from "../../../contexts/Localize/LocalizeStateProvider"
import DataSourceService from "../../../services/DataSourceService"
import { usePageLocale } from "../../../hooks/useLocale"
import { useCurrentUserHook } from "../../Authentication/useUserHook"
import { Spinner } from "../../Common/Spinner"

const OrganizationMediaFiles = (props) => {
  const {journeyProfileId, journeyUserId, organizationId, activityId, incrementCount, isPopupMode} = props
  const {currentUser} = useCurrentUserHook()
  const {localeDispatch} = useContext(LocalizeContext)
  const [requestParams, setRequestParams] = useState({
    page:1,
    per_page:15,
    fileable_type:'journey_profile',
    journey_profile_id:journeyProfileId,
    organization_id: organizationId,
    activity_id:activityId,
    data_source_params: {
      'data_source_id':[4043],
      'locale':currentUser.current_locale
    }
  })
  const [loading, setLoading] = useState(false)
  const [filesList, setFilesList] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  let [selectedItem, setSelectedItem] = useState({})
  let [formProps, setFormProps] = useState({})
  const [dataSource, setDataSource] = useState({})
  const [caseAssessmentCount, setCaseAssessmentCount] = useState(0)

  const [isFormOpen, toggleFormOpen] = useState(false)
  const {isOpen, toggleModal:toggleDeleteModal} = useModal()
  const {onSearchCallback, searchKeyword} = useSearch((s) => search(s));
  const { labelLocale } = usePageLocale(currentUser.current_locale, 'mediaLocale')

  useEffect(() => {
    requestParams.page = currentpage;
    fetchPageLocale();
    getAll();
  }, [currentpage]);
  
  const fetchPageLocale = async () => {
    const data = await DataSourceService.getPageLocale({
      data_source_ids: [4730],
      locale: currentUser.current_locale,
    });
        
    if(data?.digital_locker_locale) {
      localeDispatch({
        'type': "UPDATE",
        'payload': {
          'mediaLocale': data.digital_locker_locale,
        }
      })
    } else {
      console.log('Data or digital_locker_locale undefined:', data);
    }
  };

  const search = (search) => {
    requestParams.search = search
    currentpage===1?getAll():setCurrentpage(1)
  }

  const getAll = () =>{
    setLoading(true)
    CaseReportService.getAll(requestParams).then((res)=>{
      setFilesList(res.data.case_reports)
      setPagemeta(res.data.meta)
      setCaseAssessmentCount(res.data.case_assessment_count)
      setDataSource(res.data.data_source_list)
      setLoading(false)
    })
  }

  const {sort} = useSort(requestParams, getAll)

  const onDelete = (e) => {
    toggleDeleteModal(e)
    CaseReportService.delete(selectedItem.id).then((res)=>{
      if(res.status == 204){
        setFilesList(filesList.filter(f=> f.id !== selectedItem.id))
        incrementCount && incrementCount(-1)
      }
    })
  }

  const removeFile= (f) => {
    setSelectedItem(f)
    toggleDeleteModal()
  }

  const onFormSubmit = (report) =>{
    if(report){
      setFilesList([report, ...filesList])
      incrementCount && incrementCount(1)
    }
    toggleFormOpen(false)
  }

  const addCaseReport = (id) =>{
    setFormProps({
      'onFormSubmit':onFormSubmit,
      'isPopupMode':true,
      'isOpen':isFormOpen,
      'toggleModal': () => toggleFormOpen(false),
      'match':{
        'params':{
          'id': id,
          'activity_id':activityId
        }
      },
      'location':{
        'search':`?journey_profile_id=${journeyProfileId}&organization_id=${organizationId}`,
      }
    })
    toggleFormOpen(true)
  }

  if(isFormOpen){
    return <GenericModal component={CaseReportForm} {...formProps}/>
  }

  /*
  <div className="flex aligncenter">
    <label htmlFor="file_upload_btn" className="bg-grey-4 round-btn m-r-5 m-t-4 tooltip bg-highlight">
      <i className="fas fa-plus"/>
      <CueTip tooltip="Add Files" positionCss="bottom"/>
    </label>
    <input type="file" id="file_upload_btn" className="hidden" onChange={e => uploadFileToUrl(e.target)}/>
  </div>

  const uploadFileToUrl = (target) => {
    try {
      let file = target?.files[0];
      if(file){
        if ((file.size / 1024 / 1024) > 2) {
          target.value = null
          alert('Files to be uploaded must not exceed  2Mb size.')
          return;
        }
      }else{
        return
      }

      setLoading(true)
      const imageData = {
        'organization_id':organizationId, 
        'fileable_type':'journey_profile',
        'journey_profile_id':journeyProfileId
      }
      Cs.uploadFileToUrl(file, 'private', imageData).then((res)=> {
        if(res.status == 201){
          setFilesList([res.data.file_upload, ...filesList])
        }
        target.value = null;
        setLoading(false)
      }, (err)=>{
        setLoading(false)
      })
    } catch (e) {
      setLoading(false)
      //console.log(e)
    }
  }

  const removeFile= (file, e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      CaseReportService.delete(file.id).then((res)=>{
        if(res.status == 204 || res.status == 404){
          setFilesList(filesList.filter(f=> f.id !== file.id))
        }
      })
    } catch (e) {
      alert(e);
    }
  }*/

    const SortDirIco = ({column}) =>{
      return (
        <SortDirection sortColumn={requestParams.sort_column} 
        column={column} reverse={requestParams.reverse}/>
        
      )
    }

  return (
    <div id="ct">
        {loading ? 
          <Spinner/>
          :
          <div className={isPopupMode?'p-5':'app-content'}>
            <div className="app-header flex justspacebetween m-t-10">
              <div className="input-group m-r-20 m-t-10 m-b-5 flex-grow aligncenter" id="search_box">
                <input type="text" onChange={(e)=>onSearchCallback(e)} defaultValue={searchKeyword} 
                placeholder="Search" className="form-control input-sm"/>
                <span className="input-group-btn">
                  <p type="button" className="bg-highlight input-sm font-12">
                    <i className="fas far fa-search white font-14"/>
                  </p>
                </span>
              </div>
              <div className="flex aligncenter">
                <span className="bg-grey-4 round-btn m-r-5 m-t-4 tooltip bg-highlight" onClick={()=>addCaseReport()}>
                  <i className="fas fa-plus"/>
                  <CueTip tooltip="Add Files" positionCss="bottom"/>
                </span>

                <AddCaseAssessmentIco className="pull-right m-t-4 m-r-5 m-l-10"
                  journeyProfileId={journeyProfileId}
                  assessUserId={journeyUserId}
                  count={caseAssessmentCount}
                  assessmentData={{
                    'assessment_type':'Case Report',
                    'assessment_type_sid':'case_report' 
                  }}>
                    <span className="bg-grey-4 round-btn bg-highlight">
                      <i className="far fa-list-alt"/>
                    </span>
                </AddCaseAssessmentIco>
              </div>
            </div>

            <div className={isPopupMode?'m-b-30 scroll-auto':'app-content-section bg-white'}>
              {filesList.length === 0 ? 
                <EmptyRowAlert/>
                :
              <Table className="shadow-small">
                <THead>
                  <TR>
                    <TH onClick={() => sort('record_id')}>
                      ID
                      <SortDirIco column="record_id"/>
                    </TH>
                    <TH label="Notes" onClick={() => sort('data.notes')}>
                      {labelLocale(1)}
                      <SortDirIco column="data.notes"/>
                    </TH>
                    <TH label="File" onClick={() => sort('record_id')}>
                      {labelLocale(2)}
                      <SortDirIco column="record_id"/>
                    </TH>
                    <TH label="Form Type" onClick={() => sort('process_tab')}>
                      {labelLocale(3)}
                      <SortDirIco column="process_tab"/>
                    </TH>
                    <TH label="Date" onClick={() => sort('updated_at')}>
                      {labelLocale(4)}
                      <SortDirIco column="updated_at"/>
                    </TH>
                    <TH label="Status">
                      {labelLocale(5)}
                    </TH>
                    <TH label="Action">
                      {labelLocale(6)}
                    </TH>
                  </TR>
                </THead>
                <TBody>
                  <TableData list={filesList}
                    removeFile={removeFile}
                    currentUser={currentUser}
                    statusList={dataSource.growth_verification?.options} />
                </TBody>
              </Table>
              }

              <PaginationSM
                className="pagination-bar"
                currentPage={currentpage}
                totalItems={pagemeta.total}
                itemsPerPage={pagemeta.per_page}
                onPageChange={setCurrentpage}
                siblingCount={2}/>
            </div>
          </div>
        }
      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={onDelete}/>
    </div>
  )
}

const StatusDropDown = ({caseReport, index, statusList, currentUser}) => {
  const [list, setList] = useState(caseReport.case_report_verifications || [])
  if(statusList?.length > 0){
    
    const create = (verification) =>{
      CaseReportVerificationService.create(verification).then((res) => {
        if (res.status === 201) {
          setList([...list, res.data.case_report_verification])
        }
      })
    }

    const remove = (id) =>{
      CaseReportVerificationService.delete(id).then((res) => {
        if (res.status === 204) {
          setList(list.filter((v)=> v.id !== id))
        }
      })
    }

    const find = (value) => list?.find(v => v.data?.type == value)

    const toggle = (o) =>{
      const verification = find(o.value)
      if(verification){
        remove(verification.id)
      }else{
        create({
          'data':{
            'type':o.value,
            'type_label':o.label,
            'type_dsl_id':o.pk_id
          },
          'case_report_id': caseReport.id,
          'activity_id': caseReport.activity_id,
          'journey_profile_id': caseReport.journey_profile_id,
          'organization_id': currentUser.current_organization_id,
          'medical_facility_id': currentUser.current_medical_facility_id
        })
      }
    }

    return(
      <CheckBox list={statusList} toggle={(o)=>toggle(o)} exists={(o)=> find(o)?true:false} 
        labelKey="label" valueKey="value" name="status" onToggleReturnObject={true}/>
    )
  }

  return null
}

const TableData = ({list, removeFile, currentUser, statusList}) => list.map((o, k) => {
  return(
      <TR key={k}>
        <TD className="text-gray">
          {o.record_id}
        </TD>
        <TD>{o.data.notes}</TD>
        <TD>
          <Image file={o}/>
        </TD>
        <TD>
          {o.data_source?.process_tab?.label}
        </TD>
        <TD>
          <div>{o.author_name}</div>
          <div>{Cs.formatUpdateDate(o.updated_at)}</div>
        </TD>
        <TD>
          {o.data_source?.process_tab?.sid === "growth_measurement" ?
            <StatusDropDown caseReport={o} 
              status={o.status} 
              currentUser={currentUser}
              index={k} 
              statusList={statusList} />
            :
            null
          }
        </TD>
        <TD className="">
          <div className="flex aligncenter">
            <LinkDeleteIcon id="delete" 
              tooltip="Delete" 
              onSelect={()=>removeFile(o)}/>
          </div>
        </TD>
      </TR>
  )
})

const Image = ({file}) => {
  if(file.data){
    return(
      <div className="col-md-4 text-center">
        {file.data.file ?
          <a href={Cs.getFileUrl(file.data.file)} target="_blank">
            <img src={Cs.getIconByType(file.data.file)} className="img-responsive mxh-100 p-5 ht-uh"/> 
          </a>
          :
          null
        }
        <div className="ellipsis">{Cs.getFileName(file.data.file)}</div>
      </div> 
    )
  }

  return null
}

const FilesListBtn = ({journey, tooltip, organizationId, activityId, className, children}) => {
  const [count, setCount] = useState(journey.case_report_count || 0)
  const { isOpen: isFilesOpen, toggleModal: toggleFilesOpen} = useModal()
  const incrementCount = (number) => setCount(parseInt(count) + number);

  return(
    <>
      { children ? 
        <span className={className} onClick={() => toggleFilesOpen()}>{children}</span>
        :
        <span id="files_upload" onClick={() => toggleFilesOpen()} 
          className="bg-grey-4 round-btn m-r-5 m-t-4 tooltip">
            <i className="far fa-images" />
            <span className="badge-2 up">{count}</span>
            <CueTip tooltip={tooltip} positionCss="bottom"/>
        </span>
      }

      {isFilesOpen &&
        <GenericModal component={OrganizationMediaFiles} 
          isOpen={isFilesOpen} title="Files"
          toggleModal={toggleFilesOpen} 
          incrementCount={incrementCount}
          isPopupMode={true}
          activityId={activityId}
          journeyProfileId={journey.id} 
          organizationId={organizationId}/>
      }
    </>
  )
}

const OrganizationMediaPage = (props) => {
  const params = props.match.params;

  return <OrganizationMediaFiles isPopupMode={false} organizationId={params.organization_id}/>
}

export {OrganizationMediaFiles, FilesListBtn, OrganizationMediaPage};