import React, {useState} from "react"
import {NotificationBadge} from "./BadgePill"
import {Link} from "react-router-dom"
import {CueTip} from "./CueTooltip"
import {useCurrentUserHook} from "../Authentication/useUserHook"

const TagContainer = (props) =>{
  if(props.tag === 'a'){
    return(
      <Link {...props}>
        {props.children}
        <CueTip tooltip={props.tooltip}/>
      </Link>  
    )
  }else if(props.tag === 'span'){
    let clickProp = {}
    if(props.onSelect){
      clickProp.onClick = (e) => props.onSelect(e)
    }

    return(
      <span {...clickProp} {...props}>
        {props.children}
        <CueTip tooltip={props.tooltip}/>
      </span>  
    )
  }
}

const PhoneIcon = ({title, tooltip}) => {
  const defaultCss = 'tooltip tcenter table-icon flex aligncenter justifycenter m-r-10 pos-rel';
  
  return (
      <>
          <span className={defaultCss} tooltip={tooltip}>
              <span className="fas fa-regular fa-phone font-12"/>
              <CueTip tooltip={tooltip}/>
          </span>
          {title}
      </>
  )
}

const MapIcon = ({title, tooltip}) => {
  // const defaultCss = 'tooltip gray-icon tcenter flex aligncenter justifycenter m-r-10'
    const defaultCss = 'tooltip  tcenter table-icon flex aligncenter justifycenter m-r-10 pos-rel'
  return (
    <>
      <span className={defaultCss} tooltip={tooltip}>
        {/* <img src="images/map-icon.png" alt="icon-outline" /> */}
        <span className="fas fa-regular fa-location-dot font-12"/>
        <CueTip tooltip={tooltip}/>
      </span>
      {title}
    </>
  )
}

const LinkMapIcon = (props) => {
  const {id, onSelect, link, tooltip} = props
  //  const defaultCss = 'tooltip table-icon table-icon-lgrey flex aligncenter justifycenter m-r-10'
  const defaultCss = 'tooltip tcenter table-icon flex aligncenter justifycenter m-r-10 pos-rel'
  return (
    <TagContainer tag={link ? 'a':'span'} id={id} to={link} onSelect={onSelect} tooltip={tooltip} href="#" className={defaultCss}>
       {/* <span className="fas fa-hand-point-up white font-16"/> */}
      <span className="fas  fa-light fa-hand-point-up fa-lg font-15"/>
      {/* <i class="fa-thin fa-hand-point-up"></i> */}
      {props.children}
    </TagContainer>
  )
}

const LinkMailIcon = ({id, onSelect, link, count, tooltip}) => {
    // const defaultCss="tooltip table-icon table-icon-lgrey flex aligncenter justifycenter m-r-10 pos-rel"
  const defaultCss="tooltip tcenter table-icon flex aligncenter justifycenter m-r-10 pos-rel"
   
  return (
    <TagContainer tag={link ? 'a':'span'} id={id} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      {/* <img src="images/mail-icon.png" alt="" /> */}
      <span className="fas fa-light fa-envelope fa-lg font-15"/>
      <NotificationBadge count={count}/>
    </TagContainer>
  )
}                      

const LinkUserIcon = ({id, onSelect, link, tooltip, count}) => {
    // const defaultCss = 'tooltip table-icon table-icon-lgrey flex aligncenter justifycenter m-r-10'
  const defaultCss = 'tooltip table-icon table-icon flex aligncenter justifycenter m-r-10'
  return (
    <TagContainer tag={link ? 'a':'span'} id={id} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      {/* <img src="images/user-icon.png" alt=""/> */}
      <span className="fas fa-regular fa-user white fa-lg icon-outline"/>
      <NotificationBadge count={count}/>
    </TagContainer>
  )
}                      

const LinkDeleteIcon = ({id, onSelect, link, tooltip}) => {
  //  const defaultCss = 'tooltip table-icon table-icon-lgrey flex aligncenter justifycenter m-l-10'
  const defaultCss = 'tooltip table-icon flex aligncenter justifycenter m-l-10'
  return (
    <TagContainer tag={link ? 'a':'span'} id={id} to={link} onSelect={onSelect} className={defaultCss} tooltip={tooltip}>
      {/* <img src="images/delete-icon.png" alt="" /> */}
      <span className="fas fa- fa-trash-can fa-lg icon-outline"/>
    </TagContainer>
  )
} 

// const LinkFontAwesomeIcon = ({onSelect, link, iconCss, parentCss, countCss, count, tooltip}) => {
//   const defaultCss = 'tooltip table-icon flex aligncenter justifycenter m-l-5 pos-rel '+(parentCss?parentCss:'table-icon-border-lblue icon-outline')
//   return (
//     <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} className={defaultCss} tooltip={tooltip}>
//       <span className={iconCss}/>
//       {count != null && <NotificationBadge count={count} css={countCss}/>}
//     </TagContainer>
//   )
// }
const LinkFontAwesomeIcon = ({ onSelect, link, iconCss, parentCss, countCss, count, tooltip }) => {
  const defaultCss = `tooltip table-icon flex aligncenter justifycenter m-l-5 pos-rel ${parentCss ? parentCss : 'bg-white'}`;
  
  return (
    <TagContainer tag={link ? 'a' : 'span'} to={link} onSelect={onSelect} className={defaultCss} tooltip={tooltip}>
      <span className={`${iconCss} icon-outline`} />
      {count != null && <NotificationBadge count={count} css={countCss}/>}
    </TagContainer>
  )
}

const EnvelopeGrayIcon = ({onSelect, link, css, count, tooltip}) =>{
  const defaultCss = 'tooltip pos-rel'
  return (
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      <img src="./images/envelope-icon-gray.png" />
      <NotificationBadge count={count} css="notification-count brd-100p notification-blue fw900"/>
    </TagContainer>
  )
}

const EditGrayIcon = ({onSelect, link, css, tooltip}) =>{
  const defaultCss = 'tooltip pos-rel'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      <span className="fas fa-edit fa-lg icon-outline"/>
    </TagContainer>
  )
}

const HamburgerMenuIcon = ({onSelect, link, css, tooltip}) =>{
  const defaultCss = 'tooltip pos-rel'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} tooltip={tooltip}>
      <img src="./images/hamburger-meni-icon-gray.png" alt="" />
    </TagContainer>
  )
}

const MaximizeIcon = ({onSelect, link, css, children, tooltip}) =>{
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} className={css} tooltip={tooltip}>
      <img src="./images/maximize-icon.png"/>
    </TagContainer>
  )
}

const ChatGrayIcon = ({onSelect, count, link, css, tooltip}) => {
  const defaultCss = 'tooltip pos-rel'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      <img src="./images/mesage-icon-gray.png" />
      {count?<NotificationBadge count={count} css="notification-count brd-100p notification-blue fw900"/>:null}
    </TagContainer>
  )
}

const CheckListGrayIcon = ({onSelect, count, link, css, tooltip}) =>{
  const defaultCss = 'tooltip pos-rel'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      <img src="./images/check-icon-gray.png" />
      {count?<NotificationBadge count={count} css="notification-count brd-100p notification-blue fw900"/>:null}
    </TagContainer>
  )  
}

const UserGroupGrayIcon = ({onSelect, count, link, css, tooltip}) =>{
  const defaultCss = 'tooltip pos-rel'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      <img src="./images/user-group-icon-gray.png" />
      <NotificationBadge count={count} css="notification-count brd-100p notification-blue fw900"/>
    </TagContainer>
  )                      
}

const MapGrayIcon = ({onSelect, count, link, css, tooltip}) =>{
  const defaultCss = 'tooltip pos-rel'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      <img src="./images/map-icon-gray.png" />
      <NotificationBadge count={count} css="notification-count brd-100p notification-blue fw900"/>
    </TagContainer>
  )
}

const BookmarkGrayIcon = ({onSelect, count, link, css, tooltip}) =>{
  const defaultCss = 'tooltip pos-rel'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      <img src="./images/bookmark-icon-gray.png"/>
      <NotificationBadge count={count} css="notification-count brd-100p notification-blue fw900"/>
    </TagContainer>
  )
}

const EyeGrayIcon = ({onSelect, count, link, css, tooltip}) =>{
  const defaultCss = 'tooltip pos-rel'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      <img src="./images/eye=icon-gray.png" />
      <NotificationBadge count={count} css="notification-count brd-100p notification-blue fw900"/>
    </TagContainer>
  )
}
                        
const GlobeGrayIcon = ({onSelect, count, link, css, tooltip}) =>{
  const defaultCss = 'tooltip pos-rel'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      <img src="./images/globe-icon-gray.png" />
      <NotificationBadge count={count} css="notification-count brd-100p notification-blue fw900"/>
    </TagContainer>
  )
} 

const FontAwesomeIcon2 = ({ onSelect, link, iconCss, parentCss, countCss, count, tooltip }) => {
  const defaultCss = `tooltip table-icon flex aligncenter justifycenter ${parentCss ? parentCss : 'bg-white'}`;

  return (
    <TagContainer tag={link ? 'a' : 'span'} to={link} onSelect={onSelect} className={defaultCss} tooltip={tooltip}>
      <span className={`${iconCss} icon-outline`} />
      {count != null && <NotificationBadge count={count} css={countCss} />}
    </TagContainer>
  )
}

const CheckBtn = ({onSelect, exists, css, tooltip}) => {
  const defaultCss = 'tooltip table-icon icon-outline'+(css?css:'icon-outline table-icon bg-white')
  return(
    <button 
      onClick={onSelect}
      className={defaultCss}>
        <i className={'f16 fas icon-outline  '+(exists()?'fa-check-square icon-outline':'fa-square icon-outline')}/>
        {tooltip?<CueTip tooltip={tooltip}/>:null}
    </button>
  )
}

const DsRefLink = ({dsIds, className=""}) =>{
  const {isDevUser} = useCurrentUserHook();

  if(isDevUser, dsIds){
    return (
      <TagContainer tag="a" to={"/list/data_sources?ds_ids="+dsIds.join(',')} className={className}>
        <span className="fal fa-external-link-alt"/>
      </TagContainer>
    )
  }

  return null;
}

export {
  GlobeGrayIcon, MaximizeIcon, EnvelopeGrayIcon, EditGrayIcon, HamburgerMenuIcon, 
  PhoneIcon, MapIcon, LinkMapIcon, LinkMailIcon, LinkUserIcon, LinkDeleteIcon, 
  LinkFontAwesomeIcon, EyeGrayIcon, BookmarkGrayIcon, MapGrayIcon, UserGroupGrayIcon,
  CheckListGrayIcon, ChatGrayIcon, FontAwesomeIcon2, CheckBtn, DsRefLink
};