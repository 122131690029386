import React, {Fragment} from 'react';
import useStyle from '../../hooks/useStyle';

const ProjectMenu = ({ isOpen, toggleModal, item,addChecklist,addNotes,addDelete,type, MenuItems, menuHeight, menuCss}) => { 
    useStyle('action_sheet');
  
    if(!isOpen) return null;
    
    const menuStyle = {height: (menuHeight || '330px'), opacity: 1, display: 'block', overflowY: 'scroll'}

    return(
      <Fragment> 
        <div className={`menu-inline menu-box-bottom menu-box-detached round-medium menu-active ${menuCss}`} style={menuStyle}>
          <div className="ml-center">
            <br/>
            <span className="ml-button ml-hover-red color-red2-dark font-20" title="Close Modal" 
                style={{position: 'absolute',right: '0px',top: '3px'}} onClick={e=>toggleModal(e)}>
                <i className="fa fa-times" aria-hidden="true"></i>
            </span>
          </div>
          <div className="link-list link-list-1 content m-b-0">
            {MenuItems && <MenuItems item={item}/>}
          </div>
        </div>
      </Fragment>
    )
}

export default ProjectMenu;