import React from "react";

const ChatActions = ({ c, currentUser, deleteChat, setEdit, setReply }) => {
  return (
    <>
      {c.created_by === currentUser.id && (
        <div className="m-t-5">
          <div className="font-16 pull-left m-r-5">
            <i className="far fa-eye-slash text-muted" aria-hidden="true"></i>
          </div>
          <div className="font-16 pull-right m-r-5" onClick={() => deleteChat(c)}>
            <i className="far fa-trash-alt" aria-hidden="true"></i>
          </div>
          {!c.file_upload_id && (
            <div className="font-16 pull-right m-r-15" onClick={() => setEdit(c)}>
              <i className="far fa-edit" aria-hidden="true"></i>
            </div>
          )}
        </div>
      )}
      <div className="m-t-5">
        <div className="font-16 pull-right m-r-15" onClick={() => setReply(c)}>
          <i className="fas fa-reply" aria-hidden="true"></i>
        </div>
      </div>
    </>
  );
};

export default ChatActions;
