import React, { useState, useMemo, useRef } from "react"
import { useHistory } from "react-router-dom"
import { PortalToggleMenu } from "../../GenericPortalPage/Common"
import Cs from "../../../../services/CommonService"
import ChildForm from "../../MotherInfantJourney/Child/ChildForm"
import GenericModal from "../../../../components/Modals/GenericModal"
import useModal from "../../../../hooks/useModal"
import { NotesBox } from "../../GenericPortalPage/NotesBox"
import UsersRolesPopup from '../../../DataShareing/UsersRolesPopup'
import { usePageLocale } from "../../../../hooks/useLocale"
import { DraftBadge } from "../../../Common/NotificationPill"
import { useActivityHook } from "../Activities/List/useActivityHook"
import { MultiplePregnancy, AddMultyPregnancy } from "../../List/Common"
import { UserName } from '../../../UserProfile/UserInfoPopup'
import { StatuDropDown } from "../../List/Common"
import { CueTip } from "../../../Common/CueTooltip"
import { useCurrentUserHook } from "../../../Authentication/useUserHook"
import { DsRefLink } from "../../../Common/ImageIcons";

const ChildBlock = (props) => {
  let [associatedJourney, setAssociatedJourney] = useState(props.associatedJourney || [])
  let {journey, menuOptions, orgBgImageStyle, dataSource, schedulesMissed, orgMemberInactiveDays, updateStatus} = props
  let {last_measurement:lastMeasurement, created_user:user, member_role:role} = journey
  let [child, setChild] = useState(props.child)
  let {data, data_source} = child
  let history = useHistory()

  const {currentUser} = useCurrentUserHook()
  const {isOpen: isInfantActivityFormOpen, toggleModal:toggleInfantActivityModal } = useModal()
  const {isOpen: isNotesOpen, toggleModal:toggleNotesModal } = useModal()
  const {isOpen: isUserRoleOpen, toggleModal: toggleUserRole } = useModal()
  const {ageFormat} = useActivityHook(child)
  const {labelLocale, dsId:childBirthDsId} = usePageLocale(currentUser.current_locale, 'childBirthTab')
  const {labelLocale:casePageLocale, dsId:casePageDsId} = usePageLocale(currentUser.current_locale, 'casePortalPage')
  
  const ageAgo = useMemo(() => ageFormat(null, Cs.getCurrentDateTime()), [child.id]);
  const showOrgMemberInactiveDays = currentUser.current_organization.record_id !== 59;

  let {current:scope} = useRef({formProps:null});

  const whData = (child.child_form_id === 1176) 
    ? {
      "height":child.data?.height,
      "weight":child.data?.weight
    } 
    : {
      "height":child.data_source?.height?.label,
      "weight":child.data_source?.weight?.label
    }

  const lastWhData = (child.child_form_id === 1176) 
    ? {
      "height_baby":lastMeasurement?.data?.height_baby,
      "baby_weight":lastMeasurement?.data?.baby_weight
    } 
    : {
      "height_baby":lastMeasurement?.data_source?.height_baby?.label,
      "baby_weight":lastMeasurement?.data_source?.baby_weight?.label
    }

  const routeActivityForm = (id, journeyProfile=journey) => {
    scope.formProps = Cs.routeInfantPath(dataSource, {}, journeyProfile, history);
    scope.formProps.match.params.id = id
    scope.formProps.component = ChildForm;
    toggleInfantActivityModal()
  }

  const onFormSubmit = (child_form_data) => {
    if(!child || child?.id === child_form_data.id){
      setChild(child_form_data);
    }else{
      setAssociatedJourney(associatedJourney.filter(j => j.id !== child_form_data.journey_profile_id))
    }
    toggleInfantActivityModal(); 
  }

  const openUserRole = () => {
    toggleUserRole()
  }

  return(
    <>
      <div className="flex coldir fulwid p10">
        <div className="f16">
          <div className="flex justspacebetween">
            <div className="f24 m-r-20 text-blue-2">
              {child.record_id} - {child?.data?.baby_name || 'Baby name pending'}
              <DraftBadge isDraft={child.draft} styleName="m-l-15"/>
            </div>
            <div className="flex-auto">
              <span className="m-r-5">Age {ageAgo}</span>
              <MultiplePregnancy journey={journey} child={child}/>
            </div>
            <div className="flex aligncenter">
              <DsRefLink className="m-r-15" dsIds={[childBirthDsId, casePageDsId]}/>

              <span className="icon-black-cirle table-icon brd-30 flex aligncenter justifycenter tooltip"
                onClick={() => routeActivityForm(child?.id)}>
                <i className="fas fa-edit"/>
                <CueTip tooltip={casePageLocale(17)} positionCss="bottom"/>
              </span>

              <PortalToggleMenu journey={journey} menuOptions={menuOptions}
                journeyType="infant_journey" menuDsId='3530'
                permissionKey="infant_portal" child={child}/>
            </div>
          </div>
          <ul className="flex aligncenter m-t-15 wrap">
            <li className="m-r-20">
              {data_source?.gender?.label && data_source.gender.label}
            </li>
            {data_source?.delivery_type?.label && (
              <li className="m-r-20">
                | &nbsp; {data_source.delivery_type.label}
              </li>
            )}
            {data_source?.gestational_week?.label && (
              <li className="m-r-20">
                | &nbsp; {data_source.gestational_week.label + 'W'}
              </li>
            )}
            {child && associatedJourney && (
              <li className="m-r-20">
                | <AddMultyPregnancy
                  journeyChild={child}
                  associatedJourney={associatedJourney}
                  onSelect={(j) => routeActivityForm(j.child_id, j)}
                />
              </li>
            )}
            <li className="m-r-20">
              <StatuDropDown id={journey.id}
                status={journey.status}
                statusReason={journey.status_reason}
                currentLocale={currentUser.current_locale}
                update={updateStatus}
              />
            </li>
            {data_source?.delivery_location?.label && (
              <li className="m-r-20">
                | &nbsp; {data_source.delivery_location.label}
              </li>
            )}
          </ul>
          {/* <ul className="flex aligncenter m-t-15">
              <li className="m-r-20">
                <span className="fa fa-location-dot text-blue-2 m-r-5"/>
                {data_source?.delivery_location?.label || 'Location not available'}
              </li>
              <li className="m-r-20">
                <span className="far fa-calendar-alt text-blue-2 m-r-5"/>
                {child.data.dob_baby}
              </li>
                <>
                  {schedulesMissed?.count > 0 ?
                    <li className="m-r-20 d-grid-xss">
                      <LinkFontAwesomeIcon 
                        countCss="badge-2 up bg-lred l-10" 
                        iconCss="far fa-calendar-times white" 
                        count={schedulesMissed?.count}
                        tooltip={labelLocale(4)}/>
                    </li>
                    :
                    null
                  }
                  {showOrgMemberInactiveDays && orgMemberInactiveDays?.count > 0 ?
                    <li className="m-r-20 d-grid-xss">
                      <LinkFontAwesomeIcon 
                        countCss="badge-2 up bg-lred l-10" 
                        css="fas fa-user-clock white m-l-5" 
                        count={orgMemberInactiveDays?.count}
                        tooltip={labelLocale(3)}/>
                    </li>
                    :
                    null
                  }
                </>
            </ul> */}
          <ul className="flex aligncenter m-t-15 card-xs-content">
            <li className="m-r-20 d-grid-xss" label="Birth Details">
              <div className="text-gray font-12">{labelLocale(1)}</div>
              {child.data.dob_baby}
            </li>
            <li className="m-r-20 d-grid-xss" label="Height">
              <div className="text-gray font-12">{labelLocale(5)}</div>
              {whData.height} cms
            </li>
            <li className="m-r-20 d-grid-xss" label="Weight">
              <div className="text-gray font-12">{labelLocale(6)}</div>
              {whData.weight} kgs
            </li>
          </ul>
          <ul className="flex aligncenter m-t-15">
            {lastMeasurement ?
              <li className="m-r-20 d-grid-xss" label="Updated On">
                <div className="text-gray font-12">{labelLocale(2)}</div>
                {Cs.formatUpdateDate(lastMeasurement?.updated_at)}
              </li>
              :
              null
            }
            {lastMeasurement ?
              <>
                <li className="m-r-20 d-grid-xss" label="Height">
                  <div className="text-gray font-12">{labelLocale(5)}</div>
                  {lastWhData.height_baby} cms
                </li>
                <li className="m-r-20 d-grid-xss" label="Weight">
                  <div className="text-gray font-12">{labelLocale(6)}</div>
                  {lastWhData.baby_weight} kgs
                </li>
              </>
              :
              null
            }
          </ul>
          <ul className="flex aligncenter m-t-15">
            <li className="m-r-20 d-grid-xss" label="Username">
              <div className="text-gray font-12">{labelLocale(7)}</div>
              <UserName id={child?.created_by} role={child?.member_role}>{child?.created_user?.name}</UserName>
            </li>
            {child?.member_role?.role ?
              <li className="m-r-20 d-grid-xss" label="Role">
                <div className="text-gray font-12">{labelLocale(8)}</div>
                {child?.member_role?.role}
              </li>
              :
              null
            }
            <div className="text-gray  m-t-20">
              {currentUser.current_organization.record_id} - {journey.record_id}
            </div>
          </ul>
        </div>
      </div>
      {isInfantActivityFormOpen &&
        <GenericModal toggleModal={toggleInfantActivityModal}
          isPopupMode="true" onFormSubmit={onFormSubmit}
          closeConfirmPopup={true}
          {...scope.formProps}/>
      }

      {isNotesOpen &&
        <GenericModal title="Notes" component={NotesBox}
          toggleModal={toggleNotesModal} journeyProfileId={journey.id}
          noteableId={journey.id}
          currentUser={currentUser}/>
      }

      {isUserRoleOpen &&
        <GenericModal component={UsersRolesPopup}
          isOpen={isUserRoleOpen} toggleModal={toggleUserRole}
          itemType="journey_profile" itemId={journey.id}
          showRoles="true" showOrgMembers="true"
          title="Assign this case to Roles and/or Users"
          medicalFacilityId={journey.medical_facility_id}/>
      }
    </>
  )
}  

export default ChildBlock;