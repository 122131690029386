import React, { useState, useEffect } from "react"
import SavedReportFilterService from "./SavedReportFilterService"
import { useCurrentUserHook } from "../../../Authentication/useUserHook"

const SavedReportFilterList = ({filter, setFilter, insideAccordion = false, filterableType}) => {
  const {currentUser} = useCurrentUserHook();

  const [savedFilters, setSavedFilters] = useState([]);
  const [newFilter, setNewFilter] = useState({});

  useEffect(()=>{
    getFilters()
  }, [])

  const getFilters = async () =>{
    const {data, status} = await SavedReportFilterService.getAll({
      'page':1,
      'per_page':30,
      'user_id':currentUser.id,
      'organization_id': currentUser.current_organization_id,
      'filterable_type': filterableType
    })
    if(status === 200){
      setSavedFilters(data.filters);
    }
  }

  /*useEffect(()=>{
    if(newFilter.id){
      setSavedFilters([...savedFilters, newFilter]) 
    }
  }, [newFilter])*/

  const handleInputChange = (e) => {
    setNewFilter({ ...newFilter, [e.target.name]: e.target.value });
  };

  const saveFilter = async () => {
    console.log("Saving filter:", newFilter);
    if(newFilter?.id){
      const req = {
        ...newFilter,
        'data': filter 
      }
      const { data, status } = await SavedReportFilterService.update(req);
      if (status === 204) {
        setSavedFilters(savedFilters.map((f) => (f.id === newFilter.id ? req : f)));
        setNewFilter({});
      }
    }else{
      const req = {
        ...newFilter,
        'filterable_type': filterableType,
        'organization_id': currentUser.current_organization_id, 
        'data': filter 
      };
      const { data, status } = await SavedReportFilterService.create(req);
      if (status === 201){
        setSavedFilters([...savedFilters, data.filter]);
        //setFilter({ id: data.filter.id, ...filter });
        setNewFilter({}); 
      }
    }
  }

  const deleteFilter = (e, id) => {
    e.stopPropagation();
    const userConfirmed = window.confirm("Are you sure you want to delete this saved filter?");
    if (!userConfirmed) return;
    SavedReportFilterService.delete(id).then((res) => {
      if (res.status === 204) {
        setSavedFilters(savedFilters.filter((i) => i.id !== id));
        if (newFilter?.id === id) {
          setNewFilter({});
          setFilter({});
        }
      }
    })
  }

  const checkFilter = (e, filter) => {
    const f = filter.id === newFilter.id ? {} : filter;
    setNewFilter(f);
    setFilter(f.data || {});
  }

  return(
    <div>
       {!insideAccordion && (
        <div className="card-title card-title-gray m-t-10 flex aligncenter p10 w-full">
          <h4 className="f18 text-left">Predefined Filters</h4>
        </div>
      )}
      <div className="flex aligncenter justifycenter">
        <input value={newFilter.name || ""} onChange={handleInputChange}
          name="name" className="form-control"
          placeholder="Enter name to save filters"/>
        {newFilter?.name?.length > 0 ? 
          <span className="bg-highlight round-btn m-l-15" onClick={saveFilter}>
            <i className={newFilter.id ? "fas fa-save" : "fas fa-plus"} />
          </span>
          : null
        }
      </div>
      <div className="col-xs-12 mxh-300 scroll-auto m-t-10">
        {/*<CheckBox list={savedFilters || []} 
          labelKey="name" valueKey="id" onToggleReturnObject={true}
          toggle={(id)=>checkFilter(id)} 
          exists={(id)=>filter?.id === id} 
          name="data_filter_types"/> */}
        {savedFilters?.map((f, i)=>
          <div key={i} className="flex justspacebetween aligncenter">
            <span key={i} className="checkbox-container" onClick={(e) => checkFilter(e, f)}>
              <span>{f.name}</span>
              <input type="checkbox" checked={newFilter?.id === f.id} readOnly/>
              <span className="checkbox-checkmark"/>
            </span>

            <i className="fas fa-trash m-r-5 f14 lred"onClick={(e) => deleteFilter(e, f.id)}/>
          </div>
        )}
      </div>
    </div>
  )
}

export {SavedReportFilterList};