import React from "react";
import useStyle from '../../hooks/useStyle'

const HrTimeline = ({showMenu}) => {
  useStyle("ct_path");
  
  return(
    	<>
      {/* == classnames were based on context of project == */}
      <div className="wrap">
        <div className="timeline-wrap">
          <ul className="timeline">
            <li className="timeline-item bmw">
              <div className="p-timeline-item">
                <time className="p-timeline-date" dateTime="2019-05-01">
                  May 2019
                </time>
                <span className="p-timeline-carmodel" data-car={1}>
                  Item 1
                </span>
                <div className="p-timeline-block" />
              </div>
            </li>
            <li className="timeline-item mini">
              <div className="p-timeline-item">
                <time className="p-timeline-date" dateTime="2019-06-01">
                  June 2019
                </time>
                <span className="p-timeline-carmodel" data-car={2}>
                  Item 2
                </span>
                <div className="p-timeline-block" />
              </div>
            </li>
            <li className="timeline-item mini">
              <div className="p-timeline-item">
                <time className="p-timeline-date" dateTime="2019-07-01">
                  July 2019
                </time>
                <span className="p-timeline-carmodel" data-car={3}>
                  Ttem 3
                </span>
                <div className="p-timeline-block" />
              </div>
            </li>
            <li className="timeline-item bmw">
              <div className="p-timeline-item">
                <time className="p-timeline-date" dateTime="2019-08-01">
                  August 2019
                </time>
                <span className="p-timeline-carmodel" data-car={4}>
                  Item 4
                </span>
                <div className="p-timeline-block" />
              </div>
            </li>
            <li className="timeline-item bmw">
              <div className="p-timeline-item">
                <time className="p-timeline-date" dateTime="2019-09-01">
                  September 2019
                </time>
                <span className="p-timeline-carmodel" data-car={5}>
                  Item 5
                </span>
                <div className="p-timeline-block" />
              </div>
            </li>
            <li className="timeline-item bmw">
              <div className="p-timeline-item">
                <time className="p-timeline-date" dateTime="2019-10-01">
                  October 2019
                </time>
                <span className="p-timeline-carmodel" data-car={6}>
                  Item 6
                </span>
                <div className="p-timeline-block" />
              </div>
            </li>
            <li className="timeline-item bmw">
              <div className="p-timeline-item">
                <time className="p-timeline-date" dateTime="2019-11-01">
                  November 2019
                </time>
                <span className="p-timeline-carmodel" data-car={7}>
                  Item 7
                </span>
                <div className="p-timeline-block" />
              </div>
            </li>
            <li className="timeline-item mini">
              <div className="p-timeline-item">
                <time className="p-timeline-date" dateTime="2019-12-01">
                  December 2019
                </time>
                <span className="p-timeline-carmodel" data-car={8}>
                  Item 8
                </span>
                <div className="p-timeline-block" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default HrTimeline;