import React from "react";
import useModal from "../../hooks/useModal";
//check useToggle

const useFilterHook = ({filter = {}, setFilter}) => {

  const getCount = (name) => {
    const filterValue = filter[name];
    return Array.isArray(filterValue) ? filterValue.length : (filterValue ? 1 : 0);
  }

  const deleteByFilterKeys = (keys) =>{
    for(const key of keys){
      delete filter[key];
    }
    setFilter({...filter});
  }

  const deleteByFilterKey = (key) =>{
    delete filter[key];
    setFilter({...filter});
  }

  return {
    getCount,
    deleteByFilterKey,
    deleteByFilterKeys
  }

}

const FilterAccordionPanel = (props) => {
  const { title, count, isOpen: isOpenDefault, onClear } = props;
  const { isOpen, toggleModal } = useModal(isOpenDefault);

  return(
    <>
      <div className="sub-title m-b-5">
        <h5 className="flex-grow text-left pointer" onClick={toggleModal}>
          <span className={'m-t-4 m-l-5 ' + (isOpen ? 'fas fa-angle-down' : 'fas fa-angle-right')} />
          <span className="m-l-10 font-16">{title}</span>
        </h5>
        <div className="d-flex aligncenter">
          {count > 0 && <span className="accordion-count m-l-10">{count}</span>}
          {count > 0 && <i className="fa-solid fa-circle-xmark m-l-10 f20" onClick={onClear} />}
        </div>
      </div>
      {isOpen ? <div className="ani-opc">{props.children}</div> : null}
    </>
  )
}

const FilterPill = ({ label, count, filterKeys, filter, setFilter}) => {
  
  const clearSectionFilters = () => {
    filterKeys.forEach(key => delete filter[key]);
    setFilter({ ...filter });
  }

  return (
    count > 0 ? (
      <span className="filter-pill">
        {label} ({count})
        <button className="close-pill-btn" onClick={clearSectionFilters}>
          &times;
        </button>
      </span>
    ) : null
  )
}

export {FilterAccordionPanel, useFilterHook, FilterPill};