import React, {lazy, Suspense} from 'react'
import PrivateRoute from "../../routes/PrivateRoute"
import PublicRoute from "../../routes/PublicRoute"
import { Spinner } from '../Common/Spinner'
const DigitalContentList = lazy(() => import('../../components/DigitalContent/List/DigitalContentList'))
const DigitalContentForm = lazy(() => import('../../components/DigitalContent/Form/DigitalContentForm'))
const BlogList = lazy(() => import("./BlogList"))

const DigitalContentRouter = (props) => (
  <Suspense fallback={<Spinner/>}>
    <PrivateRoute path="/digital_contents" searchString={props.searchString} component={DigitalContentList} exact />
    <PrivateRoute path="/marketing_contents" searchString={props.searchString} component={DigitalContentList} exact />
    <PrivateRoute path="/digital_contents/edit/:id?" component={DigitalContentForm} exact />
    <PrivateRoute path="/digital_contents/details/:id?" component={DigitalContentForm} exact />
    <PrivateRoute path="/digital_contents/create/:type?/:formId?/:id?" component={DigitalContentForm} exact />
    <PublicRoute path="/blogs/:organization_id" component={BlogList} exact />
    <PrivateRoute path="/org/whatsnew/:form_id/:organization_id" searchString={props.searchString} component={DigitalContentList} exact />
  </Suspense>
)

export default DigitalContentRouter;