import React, { useContext } from "react";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import { Link } from "react-router-dom";
import useModal from "../../../hooks/useModal";
import PortalSideMenu from "./PortalSideMenu";
import { CueTip } from "../../Common/CueTooltip";
import { usePageLocale } from "../../../hooks/useLocale";
import { useCurrentUserHook } from "../../Authentication/useUserHook";

const JourneyInlineMenu = (props) => {
	const {currentUser} = useCurrentUserHook()
	let {journey} = props;
  	let {data} = journey;
  	
	return(
		<Link  className="round-btn white m-l-15" to={`/health/form/${journey.form_id}/${journey.id}`}>
        	<i className="fas fa-edit" data-tip="Edit health journey form" />
      	</Link>
  	)
}

const PortalToggleMenu = (props) => {
	const {state: { user: currentUser }} = useContext(AuthContext);
	const {labelLocale:casePageLocale} = usePageLocale(currentUser.current_locale, 'casePortalPage')
	const { isOpen: isSideNavOpen, toggleModal: toggleOpenSideNav } = useModal();

	if(props?.menuOptions?.options?.length == 0) return null;

	if(isSideNavOpen){
	  return(
		<PortalSideMenu openSideNav={isSideNavOpen} setOpenSideNav={toggleOpenSideNav} {...props}/>
	  )
	}
  
  	return (
	  <span id="case_menu" onClick={(e) => toggleOpenSideNav(e)}  
	  	className="icon-black-cirle table-icon brd-30 flex aligncenter justifycenter m-l-10 tooltip">
	  		<i className="fas fa-bars"/>
	  		<CueTip tooltip={casePageLocale(21)} positionCss="bottom"/>
	  </span>
  	)
}

export {JourneyInlineMenu, PortalToggleMenu};