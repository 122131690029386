import React, { Fragment, useState, useEffect } from "react";
import Cs from "../../../../services/CommonService";
import { PillDsList } from "../../../../components/Common/NotificationPill";
import PreLoadSpinner from "../../../../components/PreLoadSpinner";
import GenericModal from "../../../../components/Modals/GenericModal";
import TreatmentForm from "../../Treatments/Form/TreatmentForm";
import WellnessForm from "../../Wellness/Form/WellnessForm";
import useModal from "../../../../hooks/useModal";
import Tabs, { TabPane } from "rc-tabs";
import PortalSideMenu from "./PortalSideMenu";
//import ActivityMenuModal from "../List/ActivityMenuModal"
import TreatmentService from "../../Treatments/TreatmentService";
import WellnessService from "../../Wellness/WellnessService";

let treatmentRequestParams = null;
let data_source = {};
let activityForm = {
  symptoms: 404,
  diagnosis: 436,
  treatment: 437,
  daily_check: 438,
  side_effects: 435,
  pain_tracking: 434,
  palliative: 403,
};
let treatmentFormProps = null;
let wellnessFormProps = null;

let selectedItem = null;

const ActivityTable = ({ journeyProfile, history, searchString }) => {

  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  const [activityType, setActivityType] = useState("symptoms");
  const [wellnessData, setWellnessData] = useState({});
  const [treatmentData, setTreatmentData] = useState({});

  const { isOpen: isSideNavOpen, toggleModal: toggleOpenSideNav } = useModal();
  const { isOpen: treatmentFormOpen, toggleModal: toggleTreatmentFormModal } = useModal();
  const { isOpen: wellnessFormOpen, toggleModal: toggleWellnessFormModal } = useModal();

  useEffect(() => {

  }, [searchString, currentpage, activityType]);

  let getTreatmentList = (formId) => {
    treatmentRequestParams = {
      data_source_params: {
        data_source_id: [3467],
        response_type: "object",
      },
      journey_profile_id: journeyProfile.id,
      treatment_form_id: formId,
    };
    TreatmentService.getAll(treatmentRequestParams).then((res) => {
      setLoading(true);
      if (res.status == 200) {
        data_source = res.data.data_source_list;
        //requestParams.totalItems = res.data.meta.total;
        //journey_category = res.data.meta.journey_category;
        //CheckAccess.userAccess(authState.user, journey_category, res.data.meta.invited_journey_member);
        setTreatmentData(res.data.treatments);
        setLoading(false);
      }
    });
  };

  let getWellnessList = (formId) => {
    let reqDailyRecords = {
      symptom_form_id: formId,
      journey_profile_id: journeyProfile.id,
    };

    WellnessService.getAll(reqDailyRecords).then((res) => {
      setLoading(true);
      setWellnessData(res.data.symptoms);
      setLoading(false);
    });
  };

  const tabChange = function (key) {
    if (key == "symptoms") {
      setActivityType("symptoms");
    } else if (key == "diagnosis") {
      setActivityType("diagnosis");
      getTreatmentList(436);
    } else if (key == "treatment") {
      setActivityType("treatment");
      getTreatmentList(437);
    } else if (key == "daily_check") {
      setActivityType("daily_check");
      getWellnessList(438);
    } else if (key == "side_effects") {
      setActivityType("side_effects");
      getWellnessList(435);
    } else if (key == "pain_tracking") {
      setActivityType("pain_tracking");
      getWellnessList(434);
    } else if (key == "palliative") {
      setActivityType("palliative");
    }
  };

  const routetreatmentForm = (treatment_id, form_id, label, id) => {
    treatmentFormProps = {
      match: {
        params: {
          journey_profile_id: journeyProfile.id,
          treatment_id: treatment_id, //ds value check
          treatment_type: label,
          treatment_form_id: form_id,
          id: null,
        },
      },
    }
    treatmentFormProps.component = TreatmentForm
    //call form toggle
    toggleTreatmentFormModal()
  };

  const onTreatmentFormSubmit = () => {
    toggleTreatmentFormModal()
    tabChange(activityType)
  };

  const routewellnessForm = (symptom_id, form_id, label, id) => {
    wellnessFormProps = {
      match: {
        params: {
          journey_profile_id: journeyProfile.id,
          symptom_id: symptom_id, //ds value check
          type: label,
          form_id: form_id,
          id: null,
        },
      },
    }

    wellnessFormProps.component = WellnessForm
    //call form toggle
    toggleWellnessFormModal()
  };

  const onWellnessFormSubmit = () => {
    toggleWellnessFormModal()
    tabChange(activityType)
  };

  /*const routeAssess = (path) =>{
    Cs.routeInfantPath(path, child, journeyProfile, history)  
  }*/

  const PainTrackingTable = () => (
    <div className="table-sm table-responsive">
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right">
        <i className="fa-light fa-arrow-down-to-line" />
      </span>
      <span className="bg-highlight round-btn highlight font-20 m-b-5 pull-left m-r-15" onClick={(e) => routewellnessForm(2, 434, "Record Pain Sensation")} >
        <i className="fas fa-plus" />
      </span>
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th className="p-2 bold-400 text-center miw-95">Id</th>
            <th className="p-2 bold-400 text-center miw-95">Date</th>
          </tr>
        </thead>
        <tbody>{paintrackingTableData}</tbody>
      </table>
    </div>
  );

  const paintrackingTableData = wellnessData.length > 0 && wellnessData.map((a, k) => {
      let ds = a.data_source || {};
      let data = a.data || {};

      return (
        <tr key={k}>
          <td className="text-center" data-title="Id">
            {a.record_id}
          </td>

          <td className="text-center">
            {Cs.formatUpdateDate(a.created_at, "MMMM DD YYYY")}
          </td>
        </tr>
      );
    });

  const SideEffectsTable = () => (
    <div className="table-responsive">
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right">
        <i className="fa-light fa-arrow-down-to-line" />
      </span>
      <span className="bg-highlight round-btn highlight font-20 m-b-5 pull-left m-r-15" onClick={(e) => routewellnessForm(1, 435, "Track Side Effects")} >
        <i className="fas fa-plus" />
      </span>
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th className="p-2 bold-400 text-center miw-95">Id</th>
            <th className="p-2 bold-400 text-center miw-95">Date</th>
          </tr>
        </thead>
        <tbody>{sideeffectsTableData}</tbody>
      </table>
    </div>
  );

  const sideeffectsTableData = wellnessData.length > 0 && wellnessData.map((a, k) => {
      let ds = a.data_source || {};
      let data = a.data || {};

      return (
        <tr key={k}>
          <td className="text-center" data-title="Id">
            {a.record_id}
          </td>

          <td className="text-center">
            {Cs.formatUpdateDate(a.created_at, "MMMM DD YYYY")}
          </td>
        </tr>
      );
    });

  const DailyCheckTable = () => (
    <div className="table-responsive">
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right">
        <i className="fa-light fa-arrow-down-to-line" />
      </span>
      <span className="bg-highlight round-btn highlight font-20 m-b-5 pull-left m-r-15" onClick={(e) => routewellnessForm(3, 438, "Record Daily Journal")} >
        <i className="fas fa-plus" />
      </span>
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th className="p-2 bold-400 text-center miw-95">Id</th>
            <th className="p-2 bold-400 text-center miw-95">Date</th>
          </tr>
        </thead>
        <tbody>{dailycheckTableData}</tbody>
      </table>
    </div>
  );

  const dailycheckTableData = wellnessData.length > 0 && wellnessData.map((a, k) => {
      let ds = a.data_source || {};
      let data = a.data || {};

      return (
        <tr key={k}>
          <td className="text-center" data-title="Id">
            {a.record_id}
          </td>

          <td className="text-center">
            {Cs.formatUpdateDate(a.created_at, "MMMM DD YYYY")}
          </td>
        </tr>
      );
    });

  const TreatmentTable = () => (
    <div className="table-responsive">
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right">
        <i className="fa-light fa-arrow-down-to-line" />
      </span>
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-left m-r-15" onClick={(e) => routetreatmentForm(2, 437, "Record Medication Prescribed")} >
        <i className="fas fa-plus" />
      </span>
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th className="p-2 bold-400 text-center miw-95">Id</th>
            <th className="p-2 bold-400 text-center miw-95">Date</th>
            <th className="p-2 bold-400 text-center miw-95">Name</th>
            <th className="p-2 bold-400 text-center miw-95">Doctor Name</th>
            <th className="p-2 bold-400 text-center miw-95">Notes</th>
            <th className="p-2 bold-400 text-center miw-95">Purpose</th>
            <th className="p-2 bold-400 text-center miw-95">Frequency</th>
            <th className="p-2 bold-400 text-center miw-95">Medication</th>
          </tr>
        </thead>
        <tbody>{treatmentTableData}</tbody>
      </table>
    </div>
  );

  const treatmentTableData = treatmentData.length > 0 && treatmentData.map((a, k) => {
      let ds = a.data_source || {};
      let data = a.data || {};

      return (
        <tr key={k}>
          <td className="text-center" data-title="Id">
            {a.record_id}
          </td>

          <td className="text-center">
            {Cs.formatUpdateDate(data.date_formatted_date, "MMMM DD YYYY")}
          </td>

          <td className="text-center">
            <div>{data.name}</div>
          </td>

          <td className="text-center">
            <div>{data.name_doctor}</div>
          </td>

          <td className="text-center">
            <div>{data.notes}</div>
          </td>

          <td className="text-center">
            <div>{data.purpose}</div>
          </td>

          <td className="text-center">
            <PillDsList
              list={ds.frequency}
              dataKey="label"
              styleName="pill text-left"
            />
          </td>

          <td className="text-center">
            <PillDsList
              list={ds.quantity}
              dataKey="label"
              styleName="pill text-left"
            />
          </td>
        </tr>
      );
    });

  const DiagnosisTable = () => (
    <div className="table-responsive">
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right">
        <i className="fa-light fa-arrow-down-to-line" />
      </span>
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-left m-r-15" onClick={(e) => routetreatmentForm(1, 436, "Record Appointment Details")} >
        <i className="fas fa-plus" />
      </span>
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th className="p-2 bold-400 text-center miw-95">Id</th>
            <th className="p-2 bold-400 text-center miw-95">Date</th>
            <th className="p-2 bold-400 text-center miw-95">Name</th>
            <th className="p-2 bold-400 text-center miw-95">Notes</th>
            <th className="p-2 bold-400 text-center miw-95">Appt</th>
            <th className="p-2 bold-400 text-center miw-95">Follow up</th>
          </tr>
        </thead>
        <tbody>{diagnosisTableData}</tbody>
      </table>
    </div>
  );

  const diagnosisTableData = treatmentData.length > 0 && treatmentData.map((a, k) => {
      let ds = a.data_source || {};
      let data = a.data || {};

      return (
        <tr key={k}>
          <td className="text-center" data-title="Id">
            {a.record_id}
          </td>

          <td className="text-center">
            {Cs.formatUpdateDate(a.created_at, "MMMM DD YYYY")}
          </td>

          <td className="text-center">{data.name}</td>

          <td className="text-center">{data.notes}</td>
          <td className="text-center">
            <PillDsList
              list={ds.appt}
              dataKey="label"
              styleName="pill text-left"
            />
          </td>
          <td className="text-center">
            <PillDsList
              list={ds.follow_up}
              dataKey="label"
              styleName="pill text-left"
            />
          </td>
        </tr>
      );
    });

  /*
  {CheckAccess.hasAccess(permission, 'track') && 
    <Link data-tip="View path details" to={`/infant/page/${a.id}`} className="fa far fa-map font-18 text-muted" />
  }
*/

  if (loading) return <PreLoadSpinner />;

  return (
    <Fragment>
      <div className="content">
        <div className="bg-white">
          <div className="row m-t-15 bg-white">
            <Tabs defaultActiveKey={activityType} onChange={tabChange}>
              <TabPane tab="Symptoms" key="symptoms"></TabPane>
              <TabPane tab="Diagnosis" key="diagnosis">
                <DiagnosisTable />
              </TabPane>
              <TabPane tab="Treatment" key="treatment">
                <TreatmentTable />
              </TabPane>
              <TabPane tab="Daily Check" key="daily_check">
                <DailyCheckTable />
              </TabPane>
              <TabPane tab="Side Effects" key="side_effects">
                <SideEffectsTable />
              </TabPane>
              <TabPane tab="Pain Tracking" key="pain_tracking">
                <PainTrackingTable />
              </TabPane>
              <TabPane tab="Palliative" key="palliative"></TabPane>
            </Tabs>
          </div>
        </div>
      </div>

      {isSideNavOpen && (
        <PortalSideMenu
          history={history}
          openSideNav={isSideNavOpen}
          setOpenSideNav={toggleOpenSideNav}
          journeyProfileId={journeyProfile.id}
        />
      )}
      {treatmentFormOpen && (
        <GenericModal
          toggleModal={toggleTreatmentFormModal}
          isPopupMode="true"
          onFormSubmit={onTreatmentFormSubmit}
          {...treatmentFormProps}
        />
      )}
      {wellnessFormOpen && (
        <GenericModal
          toggleModal={toggleWellnessFormModal}
          isPopupMode="true"
          onFormSubmit={onWellnessFormSubmit}
          {...wellnessFormProps}
        />
      )}
    </Fragment>
  );
};

export default ActivityTable;