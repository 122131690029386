import React, { useState, useEffect } from "react";
import useModal from "../../hooks/useModal";
import HelpService from './HelpService';
import useStyle from '../../hooks/useStyle';
import FilterSlider from "../Common/FilterSlider";
import { PlannerVideoPlayer } from '../Common/VideoPlayer';
import { DivRichTextView } from "../Common/RichTextView";
import { useCurrentUserHook } from "../Authentication/useUserHook";
import { NavIcon } from "../Common/MenuItem";
import { Spinner } from "../Common/Spinner";

const HelpSidePanel = (props) => {
  useStyle('help');

  const {currentUser} = useCurrentUserHook();
  const [loading, setLoading] = useState(true);
  const [helps, setHelps] = useState();

  useEffect(() => {
    if(props.recordIds || props.tagIds){
      getHelp();   
    }else{
      console.error("No Help id passed");
    }
  }, [])

  let getHelp = () => { 
    setLoading(true);
    let req = {
      'parent_ids':props.recordIds, 
      'tag_ids':props.tagIds,
      //'locale':currentUser.current_locale
    }

    if(props.filterByOrg){
      req.filter = {
        'assigned_to_organization_id':currentUser.current_organization_id
      }
    }

    HelpService.get(null, req).then((res)=>{
      if(res.status === 200){
        setHelps(res.data.help || []);
        setLoading(false);
        
        if (res.data.help && res.data.help.length > 0) {
          props.setTitle(res.data.help[0].data?.title || "Help");
        }
      }
    })
  }

  return(
    <div id="ct" className="p-l-15 p-r-10 z-idx-99">
      {loading ?
        <Spinner/>
        :  
        <>
          {helps.map((h, i) =>
            <div key={i}>
              <h5 className="pointer br-b-grey plr-10">
                {/* {h.data?.title} */}
              </h5>
              <div className="vh-90 scroll-x">
                <HelpDetails h={h}/>
              </div>
            </div>
          )}
        </>
      }
    </div>      
  )
}

const HelpDetails = ({h, is}) =>{
  const { isOpen:playVideo, toggleModal:togglePlayVideo } = useModal();

  if(h.data.video_url){
    const params = new URL(h.data.video_url).searchParams;
    h.data.img_url = `https://img.youtube.com/vi/${params.get('v')}/mqdefault.jpg`;
  }

  return(
    <>
      <DivRichTextView id="help_img" html={(h.privacy_type==='public' ? h.data?.public_help_detail : h.data?.help_detail)}/>
      {h.data.video_url ?
        <>
          <div className="border-dashed-sm play-btn p5" onClick={()=>togglePlayVideo()}>
            <img src={h.data.img_url} className="img-thumbnail img-rounded img-responsive center-block"/>
          </div>
          {playVideo ? <PlannerVideoPlayer url={h.data.video_url} toggleModal={togglePlayVideo}/> :null}
        </>
        :null
      }
    </>
  )
}

const HelpIcon = (props) =>{
  const {recordIds, tagIds, className, showBtn} = props;

  const [title, setTitle] = useState("Help"); 
  const { isOpen:isPanelOpen, toggleModal:togglePanel } = useModal();

  if(!(recordIds || tagIds)) return null;

  return(
    <>
      {showBtn ?
        <NavIcon
          id="help"
          dataTip="Info"
          positionCss="left"
          onSelect={() => togglePanel()}
          className="bg-highlight round-btn float-right m-l-15"
          icon="fa-regular fa-info-circle"/>
        :
        <span data-tip="Help" className={className || 'm-l-15 f18'} onClick={()=>togglePanel()}>
          <i className="fa-regular fa-info-circle"/>
        </span>
      }
      {isPanelOpen &&
        <FilterSlider
          title={title}
          component1={(props) => <HelpSidePanel {...props} setTitle={setTitle}/>}
          openSideNav={isPanelOpen}
          setOpenSideNav={togglePanel}
          {...props}/>
      }
    </>
  )
}

export {HelpIcon, HelpDetails};