import React from 'react';
import CheckAccess from "../Roles/CheckAccess";

const FormFooter = ({labelLocale, template, currentUser, download, showFeedbackLink=true}) =>{
	return(
		<div className="flex justspacebetween">
      <div>
        {(CheckAccess.isDevUser(currentUser.email) && download) ? 
          <span className="m-l-5 f20" onClick={()=>download()}>
            <i className="fas fa-file-download w-30 color-black" />
          </span>
          :
          null
        }
        <small className="m-l-5 d-block">cT{template.updated_at}-{template.id}</small>
        <small className="m-l-5 lgrey d-block">
          <i className="m-r-5 far fa-copyright" aria-hidden="true"/>
          {new Date().getFullYear()} IPIPAL Inc. All rights reserved.
        </small>
      </div>
      {/*showFeedbackLink ?
        <div>
          <Link id="feedback_form" className="font-13 m-t-20 highlight" to={`/form/feedback/${template.id}`}>
            <u>{labelLocale(11)}</u>
          </Link>
        </div>
        :
        null*/
      }
    </div>
	)
}

export {FormFooter}