import React, { useEffect, useState } from "react";
import { FormFieldCard } from "../../Common/FormFieldsView";
import OrganizationMemberService from "./OrganizationMemberService";
import FormTemplateService from "../../../services/FormTemplateService";
import { useCurrentUserHook } from "../../Authentication/useUserHook";

const OrgMemberBlock = (props) => {
  const {currentUser} = useCurrentUserHook();

  const [loading, setLoading] = useState(true);
  const [formFields, setFormFields] = useState([]);
  const [orgMember, setOrgMember] = useState({});

  let {journey, menuOptions, dataSource} = props;
  let {data, data_source} = journey;
  
  useEffect(()=>{
    getOrgMember();
  }, [])

  let getOrgMember = async () => { 
    const {status, data} = await OrganizationMemberService.get(currentUser.current_member?.id);
    setOrgMember(data.organization_member);
    getFormFields(data.organization_member.form_id);
  }

  let getFormFields = (formId) => { 
    FormTemplateService.getFormFields({
      'ids':[formId], 
    }).then((res)=>{
      if(res.status === 200){
        setFormFields(res.data.form_fields[formId]);
        setLoading(false);
      }
    })
  }

  return (
    <div className="p10">
      <div className="mxh-250 m-t-5 scroll-auto">
        <div className="table card-table p-5">
          <FormFieldCard formFields={formFields} 
            data={orgMember.data} dataSource={orgMember.data_source}/>
        </div>  
      </div> 
    </div>
  ) 
}

export default OrgMemberBlock;