import React from "react";

const SocialMediaIcons = () => {
  const socialLinks = [
    { src: "images/fb-icon.png", alt: "Facebook" },
    { src: "images/instagram-icon.png", alt: "Instagram" },
    { src: "images/twitter-icon.png", alt: "Twitter" },
    { src: "images/linked-icon.png", alt: "LinkedIn" },
    { src: "images/whatsapp-icon.png", alt: "WhatsApp" },
  ];

  return (
    <div className="m-t-10 mx-auto">
      <ul className="flex gap-5 justify-center">
        {socialLinks.map((link, index) => (
          <li key={index}>
            <a href="#">
              <img src={link.src} alt={link.alt} />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SocialMediaIcons;
