import React, { Fragment, useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import { CacheContext } from "../../contexts/Cache/CacheStateProvider";
import AuthenticationService from "../../services/AuthService";
import useStyle from '../../hooks/useStyle';
import { useHistory } from "react-router-dom";
import { useClientSearch } from '../../hooks/useSearch';
import { SideMenuRoute } from "../Routes/Routing";
import { Spinner } from "../Common/Spinner";
import {useSubscriptionAccess} from "../Subscriptions/useSubscription";
//import SubFacilityService from '../HealthJourney/MedicalCareFacility/SubFacility/SubFacilityService'

const OrganizationsModal = (props) => {
  useStyle('dashboard');  

  const history = useHistory();

  const { state: { isAuthenticated, user: currentUser }, dispatch} = useContext(AuthContext);
  const { cacheDispatch } = useContext(CacheContext);

  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState(currentUser.organizations || []);
  const {onSearch} = useClientSearch(currentUser.organizations, setOrganizations);
  const {setExistingSubscription} = useSubscriptionAccess();

  const updateCurrentOrg = (org, mc, smc) => {
    /*if(!org.is_active) return null*/
    setLoading(true);
    let req = {
      'current_organization_id': org.id,
      'current_medical_facility_id': mc?.id || null,
      'current_sub_facility_id': smc?.id || null,
      'context': 'switch_organization',
      'current_locale': (org?.data_source?.language?.sid || 'english')
    }
    AuthenticationService.updateUserName(req).then(({status, data}) => {
      if (status === 200) {
        //currentUser.current_organization_id = data.current_organization.id
        //currentUser.current_organization = data.current_organization
        //currentUser.current_member = data.current_member
        //currentUser.current_medical_facility_id = data?.current_medical_facility?.id
        //currentUser.current_medical_facility = data.current_medical_facility

        let {user} = data;

        //set subscription
        setExistingSubscription(user);

        //Remove all session storage items:
        window.sessionStorage.clear();

        cacheDispatch({
          type: "UPDATE",
          payload: {}
        })

        dispatch({
          type: "UPDATE",
          payload: { user: user }
        })

        setLoading(false);
        SideMenuRoute(history, user.current_member?.home_page, user);
      } 
    })
  }

  useEffect(()=>{
    setTimeout(function() {
      const ele = document.getElementById("active_org");
      if(ele)ele.scrollIntoView();
    }, 500);
  }, [])

  /*useEffect(() => {
    document.addEventListener("click", handleClick, true)
    return () => {
      document.removeEventListener("click", handleClick, true)
    }
  }, [])*/

  /*const handleClick = (e) => {
    if (props.toggleModal && !orgEl.current.contains(e.target)){
      props.toggleModal(e)
    }
  }*/
  
  /*const getSubFacilities = (organization_id, medical_facility_id) =>{
    setSubFacilityList([])
    let req = {
      organization_id: organization_id,
      medical_facility_id: medical_facility_id
    }
    SubFacilityService.getMemberSubFacilities(req).then(({status, data})=>{
      if(status === 200){
        setSubFacilityList(data.sub_facilities)
      }
    })
  }*/

  /*const MedicalSubFacilities = ({org, facility, list=[]}) => list.map((smc, i) => {
    return(
      <Fragment key={i}>
        <div 
          className={`d-flex ptb-13 br-l2 m-l-20 ${currentUser.current_sub_facility_id === smc.id?'org-active':'grey-hov'}`}>
          <span className="fas fa-globe brd-50p font-24"/>
          <div className="m-l-10">  
            <h5 className="d-title">{org.name} - {facility.name} - {smc.name}</h5>
          </div>
          <span onClick={()=>updateCurrentOrg(org, facility, smc)} className="fas fa-plus fa-round-ico ml-auto"/>
        </div>  
      </Fragment>
    )
  })*/  
  
  if(!isAuthenticated)
    return null

  if(loading)
    return <Spinner/>

  return(
    currentUser.organizations.length > 0 ? 
      <OrganizationList {...{currentUser, organizations, updateCurrentOrg, onSearch}}/>
      :
      <div className="lred">Empty</div>
  )
}

const OrganizationList = ({currentUser, organizations, updateCurrentOrg, onSearch}) =>{
  const [openOrgId, setOpenOrgId] = useState()
  const [searchText, setSearchText] = useState("");

  const toggleMcFacility = (orgId) =>{
    setOpenOrgId(openOrgId === orgId?null:orgId);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    onSearch(e);
  };

  const clearSearch = () => {
    setSearchText("");
    onSearch({ target: { value: "" } });
  }

  return(
    <div className="ml-container vh-80 scroll-y">
      <div className="ml-section">
        <div className="content">
          {currentUser.organizations.length > 5 ? (
            <div className="search-container">
              <input type="text" value={searchText} onChange={handleSearch} placeholder="Search" className="form-control"/>
              {searchText && (
                <i className="fas fa-times search-close" onClick={clearSearch}></i>
              )}
            </div>
          ) : null}
          <Organizations {...{currentUser, organizations, updateCurrentOrg, openOrgId, toggleMcFacility}}/>
        </div>
      </div>
    </div>
  )
}
const Organizations = ({ organizations = [], currentUser, updateCurrentOrg, openOrgId, toggleMcFacility }) => (
  <div className="organization-list full-width">
    {organizations.map((o, i) => {
      const iSCurrentOrg = currentUser.current_organization_id === o.id;
      const hasMedicalFacilities = o.medical_facilities?.length > 0;

      return (
        <Fragment key={i}>
          <div
            className={`organization-item d-flex br-l2 ${iSCurrentOrg ? "org-active" : "l-grey-hov"}`}
            onClick={() => (hasMedicalFacilities ? toggleMcFacility(o.id) : updateCurrentOrg(o))}>
            {hasMedicalFacilities && (
              <span className={`m-t-4 m-l-5 ${openOrgId === o.id ? "fas fa-angle-down" : "fas fa-angle-right"}`} />
            )}
            <div className={`m-l-10 ${o.is_active ? "" : "op-06"}`}>
              <h5 className="pointer">
                {o.name || o.author.name} {hasMedicalFacilities ? `- ${o.medical_facilities.length}` : ""}
              </h5>
              {!o.is_active && <p className="lred">Inactive</p>}
            </div>
            {iSCurrentOrg && <span className="fas fa-check fa-round-ico ml-auto" />}
          </div>

          {o.is_active && openOrgId === o.id && hasMedicalFacilities && (
            <div className="m-l-40">
              <MedicalFacilities key={i} org={o} list={o.medical_facilities} updateCurrentOrg={updateCurrentOrg} currentUser={currentUser} />
            </div>
          )}
        </Fragment>
      );
    })}
  </div>
);


const MedicalFacilities = ({org, list=[], updateCurrentOrg, currentUser}) => list.map((mc, i) => {
  const isCurrentMf = currentUser.current_medical_facility_id === mc.id
  return(
    <Fragment key={i}>
      <div onClick={()=>updateCurrentOrg(org, mc)} id={isCurrentMf?'active_org':i}
        className={`d-flex ptb-13 br-l2 ${isCurrentMf?'org-active':'l-grey-hov'}`}>
        {/* <span className="fas fa-arrow-right font-24 highlight"/> */}
          <span className="fas fa-angle-right"/>
        <div className={mc.is_active?'m-l-10':'op-06 m-l-10'}>  
          <h5>{org.name} - {mc.name}</h5>
          {!mc.is_active && <p className="lred">Inactive</p>}
        </div>
        {isCurrentMf?<span className="fas fa-check fa-round-ico ml-auto"/>:null}
      </div> 
    </Fragment>
  )
}) 

export default OrganizationsModal;