import React, { useState, useEffect, useMemo } from "react"
import Cs from "../../../../../services/CommonService"
import { FormFieldCard } from "../../../../Common/FormFieldsView"
import ActivityService from "../../Activities/ActivityService"
import useStyle from '../../../../../hooks/useStyle'
import { LinkFontAwesomeIcon } from "../../../../Common/ImageIcons"
import { DraftBadge } from "../../../../Common/NotificationPill"
import { useActivityHook, useAccessBfHook } from "./useActivityHook"
import CheckAccess from '../../../../Roles/CheckAccess'
import { FormSubmissionReview } from '../../../../Reviews/FormSubmissionReview'
import { useReviewHook } from '../../../../Reviews/useReviewHook'
import querystringify from "querystringify"
import CTLazyLoad from "../../../../Common/CTLazyLoad"
import { useCurrentUserHook } from "../../../../Authentication/useUserHook"
import { Spinner } from "../../../../Common/Spinner"

const Header = ({activity, caseName}) => (
  <div className="card-title card-title-gray flex p-b-15 p20 coldir">
    <h4 className="fw700 f18 card-heading flex coldir text-left">
      {caseName}
    </h4>
    <div className="f14 m-t-10 fw500">
      {activity.author_name} 
      &nbsp;-&nbsp; 
      {Cs.formatUpdateDate(activity.updated_at)}
      <DraftBadge isDraft={activity.draft}/>
    </div>
  </div>
)

const Footer = ({caseId, caseName, childName, activity, showWhatsapp=false, currentUser, routeActivityForm, toggleModal, deleteConfirm, sid, whatsAppPhoneNo}) => {
  return(
    <div className="card-footer flex justspacebetween p20 aligncenter">
      <ul className="flex aligncenter">
        <li className="m-r-25">{activity.record_id}</li>
  	    {CheckAccess.hasMcjActivityEditAccess(currentUser, activity, sid) ?
         	<>
		        <li className="m-r-25">
		         	<LinkFontAwesomeIcon 
		            tooltip="Edit"
		            iconCss="fas fa-edit white"
		 						onSelect={e=>{routeActivityForm(e, activity.id);toggleModal()}}/>
		        </li>
		        <li className="m-r-25">
		          <LinkFontAwesomeIcon 
		            tooltip="Delete"
		            iconCss="far fa-trash-alt white"
								onSelect={() => deleteConfirm(activity)}/>
		        </li>
	        </>
	        :null
	      }
	      {showWhatsapp ? 
		      <WhatsAppIco {...{caseId, caseName, childName, activity, whatsAppPhoneNo}}/>
			    :
			    null
			  }
      </ul>
    </div>
  )
}

const WhatsAppIco = ({caseId, caseName, childName, activity, whatsAppPhoneNo}) =>{
	const sendToWhatsApp = () =>{
		const encoded = Cs.b64EncodeUnicode(caseId +' - '+ (caseName || '') + ' ' + (childName || ''))
    const u = window.encodeURI(`https://${window.location.hostname}/mcj/activity/${activity.journey_profile_id}/${activity.id}?cn=${encoded}`)
    if(whatsAppPhoneNo){
    	window.open(`https://wa.me/+91${whatsAppPhoneNo}?text=${u}`);
    }else{
    	window.open(`https://wa.me?text=${u}`);
    }
  }

	return(
		<li className="m-r-25">
		  <LinkFontAwesomeIcon 
		    tooltip="What's app"
		    iconCss="fab fa-whatsapp white font-18"
		  	onSelect={() => sendToWhatsApp()}/>
		</li>
	)
}

const GrowthMeasure = ({a, data, ds, child, isReviewEnabled, showReviewForm, reviewByClientId}) =>{
	const {ageFormat, getBgColorByZscore, getBgColorByZscoreLable, setChild} = useActivityHook(child)

	data.age_ago = useMemo(() => ageFormat(a.id, a.scheduled_date, data), [a.id]);
  const weightZscoreStyle = useMemo(() => getBgColorByZscore(data.weight_zscore, data.weight_zscore_label), [data.weight_zscore, data.weight_zscore_label]);
  const heightZscoreStyle =  useMemo(() => getBgColorByZscore(data.height_zscore, data.height_zscore_label), [data.height_zscore, data.height_zscore_label]);
  const wfhZscoreStyle =  useMemo(() => getBgColorByZscore(data.wfh_zscore, data.wfh_zscore_label), [data.wfh_zscore, data.wfh_zscore_label]);

	return (
		<>
			<div className="border-dashed-sm p-b-15 m-b-15">
	      <h5 className="fw500 m-b-5">Age</h5>
	      <div>{data.age_ago}</div>
	    </div>
	    <div className="border-dashed-sm p-b-15 m-b-15">
	      <h5 className="fw500 m-b-5">W</h5>
	      {ds.baby_weight && 
          <ul className="flex aligncenter badge-boxes">
            <li className="badge" style={weightZscoreStyle.r1}>{ds.baby_weight?.label} kgs</li>
            <li className="badge" style={weightZscoreStyle.r2}>{data.weight_zscore} Z</li>
            <li className="badge bg-lpink text-pink">{Cs.round(data.weight_percentile)}%</li>
            <li className="badge" style={weightZscoreStyle.r4}>{data.weight_zscore_label}</li>
          </ul>
        }
        {isReviewEnabled?
        	<FormSubmissionReview formId={a.activity_form_id} fieldClientId="baby_weight" 
        		fieldIndex="baby_weight" itemId={a.id} itemType="infant.activity"
        		editReview={reviewByClientId.baby_weight} journeyProfileId={a.journey_profile_id}
        		showReviewForm={showReviewForm} organizationId={a.organization_id}/>
        	:
        	null
        }
	    </div>
	    <div className="border-dashed-sm p-b-15 m-b-15">
	      <h5 className="fw500 m-b-5">H</h5>
	      {ds.height_baby &&
            <ul className="flex aligncenter badge-boxes">
              <li className="badge" style={heightZscoreStyle.r1}>{ds.height_baby?.label} cms</li>
              <li className="badge" style={heightZscoreStyle.r2}>{data.height_zscore} Z</li>
              <li className="badge bg-lpink text-pink">{Cs.round(data.height_percentile)}%</li>
              <li className="badge" style={heightZscoreStyle.r4}>{data.height_zscore_label}</li>
            </ul>
        }
        {isReviewEnabled?
        	<FormSubmissionReview formId={a.activity_form_id} fieldClientId="height_baby" 
        		fieldIndex="baby_weight" itemId={a.id} itemType="infant.activity"
        		editReview={reviewByClientId.height_baby} journeyProfileId={a.journey_profile_id}
        		showReviewForm={showReviewForm} organizationId={a.organization_id}/>
        	:
        	null
        }
	    </div>
	    <div className="border-dashed-sm p-b-15 m-b-15">
	      <h5 className="fw500 m-b-5">WFH</h5>
	      {data.wfh && 
          <ul className="flex aligncenter badge-boxes">
            <li className="badge" style={wfhZscoreStyle.r1}>{data.wfh}</li>
            <li className="badge" style={wfhZscoreStyle.r2}>{data.wfh_zscore} Z</li>
            <li className="badge bg-lpink text-pink">{Cs.round(data.wfh_percentile)}%</li>
            <li className="badge d-hide" style={wfhZscoreStyle.r4}>{data.wfh_zscore_label}</li>
          </ul>
        }
	    </div>
  	</>
  )
}

const AccessBFCard = ({currentUser, showWhatsapp, caseId, caseName, childName, labelLocale, activity, formFields, openNotification, routeAssess, checkAlertMessage, scrollEnabled=true}) =>{
  const {data, data_source} = activity
  const msg = !activity.id && checkAlertMessage(activity)

  return(
    <div id="ct">
        <div className="bg-white brd-10 card-shadow m-b-15">
          <div className="card-title card-title-gray flex justspacebetween p-b-15 p20 f16">
            {caseName ? caseName : 'Assess BF'} 
            {activity.id? '' : Cs.formatUpdateDate(activity.scheduled_date)}
            <div>
              {activity.author_name} - {activity?.member_role?.role} - {Cs.formatUpdateDate(data?.record_date)}
            </div>
          </div>
          <div className={scrollEnabled?"p20 ht-240 scroll-auto":"p20 clearfix"}>
            <div className="wk-center">
              {!activity.id && 
                <>
                  {Cs.compareDate(activity.scheduled_date, null, 'days', false, true) <= 0 &&
                    <div className="text-center p-t-15p pointer">
                      <button onClick={e=>routeAssess(activity.id, activity.schedule_id)}
                        type="button" className="btn btn-icon-add btn-sm text-white m-b-5"
                        label="Add">
                          {labelLocale(3)}
                      </button>
                    </div>
                  }
                  <div className="m-t-15 w-100">  
                    {msg}
                  </div>
                </>
              }
            </div>
            {activity.id && <AccessBFReportList activity={activity} formFields={formFields}/>}
        </div>
        <div className="card-footer flex justspacebetween p20 aligncenter mih-72">
          <ul className="flex aligncenter">
            {CheckAccess.hasMcjActivityEditAccess(currentUser, activity, 'assess_bf') ?
            	<>
		            {activity.nutrition_care_alert_count > 0 &&
		              <li className="m-r-20">
		                <LinkFontAwesomeIcon 
		                  tooltip="Notifications"
		                  iconCss="fas fa-envelope white"
		                  count={activity.nutrition_care_alert_count}
		                  onSelect={(e)=>openNotification(e, activity)} />
		              </li>
		            }
		            {activity.id &&
		              <li className="m-r-20">
		                <LinkFontAwesomeIcon 
		                  tooltip="Edit"
		                  iconCss="fas fa-edit white"
		                  onSelect={()=>routeAssess(activity.id, activity.schedule_id)} />
		              </li>
		            }
		          </>
		          :
		          null
		        }
            {showWhatsapp ? 
				      <WhatsAppIco {...{caseId, caseName, childName, activity}}/>
					    :
					    null
			  		}
          </ul>
        </div>
      </div>
    </div>
  ) 
}

const AccessBFReportList = ({activity, formFields}) =>{
	const {data, data_source} = activity;
	const { groupAccessBFReport } = useAccessBfHook();
	const accessBfReport = groupAccessBFReport(formFields, data_source);
	
	return(
		<>
      <div className="col-xs-12 col-sm-6">
        <table className="table table-stripe table-bordered">
          <thead>
          	{/* <tr>
              <th className="text-center">
                <div className="mih-23">
                	<div>{activity.author_name}</div>
                	<div>{activity?.member_role?.role}</div>
                </div>
              </th>
            </tr> */}
            <tr>
              <th>
                <div className="badge badge-danger f16 flex aligncenter justifycenter w-full black">
                  <i className="fas fa-thumbs-down w-16px m-r-10" alt=""/>
                  Not Ok - {accessBfReport[2]?.length}
                </div>
              </th>
            </tr>
          </thead>
	        <tbody>
	          <BFPanelList className="dot-red-dark" list={accessBfReport.neg}/>
	        </tbody>
      	</table>
    	</div>
	    <div className="col-xs-12 col-sm-6">
	      <table className="table table-stripe table-bordered">
	        <thead>
	        	{/* <tr>
	            <th className="text-center">{Cs.formatUpdateDate(data?.record_date)}</th>
	          </tr> */}
	          <tr>
	            <th>
	              <div className="badge f16 card-bg-green flex aligncenter justifycenter w-full text-center black">
	                <i className="fas fa-thumbs-up w-16px m-r-10" alt=""/>
	                Ok - {accessBfReport[1]?.length}
	              </div>
	            </th>
	          </tr>
	        </thead>
	      <tbody>
	        <BFPanelList className="dot-green-dark" list={accessBfReport.pos}/>
	      </tbody>
	     </table>
	    </div>
	  </>
	)
}

const BFPanelList = ({list = [], formFieldAlerts={}, openCounselingAlert, className, isPillView}) => list ? list.map((a, i) => {
  const fieldAlerts = formFieldAlerts[a.clientId];
  
  if(isPillView){
    return(
      <div key={i} className={`badge ${className} ${a.className} f16 w-full black`}>
        <div onClick={()=>{openCounselingAlert && openCounselingAlert(fieldAlerts)}}>{a?.fieldlabel} {a.count}</div> 
        {fieldAlerts ?
          <div className="badge-2 bg-lred m-l-5">*</div>
          :null
        }
      </div>
    )
  }

  return(
    <tr key={i}>
      <td>
        <div className="flex aligncenter">
          <div className={`dots m-r-10 ${className}`}></div>
          <div>{a?.fieldlabel} {a.count}</div>
        </div>
      </td>
    </tr>
  )  
}):null

const ActivityCard = ({caseId, sid, formFields, activity, child, componentName, caseName, childName, currentUser, routeActivityForm, toggleModal, deleteConfirm, isReviewEnabled, showReviewForm, showWhatsapp=true, enableScroll=true, whatsAppPhoneNo}) =>{
	useStyle('/css/tile_components.css', true);

	const {reviewByClientId, isReviewLoading, reviewOptions} = useReviewHook({
    itemId:activity.id, 
    itemType:'infant.activity', 
    organizationId:activity.organization_id,
    dataSourceId:4744,
    isReviewEnabled
  })
	
	if(isReviewLoading) return <Spinner/>;

	const formReviewProps = {
    "isReviewEnabled":isReviewEnabled,
    "showReviewForm":showReviewForm,
    "itemId":activity.id,
    "itemType":"infant.activity",
    "reviewByClientId":reviewByClientId,
    "journeyProfileId":activity.journey_profile_id,
    "organizationId":activity.organization_id,
    "reviewOptions":reviewOptions
  }
	
	return (
	  <div id="ct">
	    <div className="bg-white brd-10 card-shadow m-b-15">
	      <Header activity={activity} caseName={caseName}/>
	      <div className="card-content p20">
	        <div className={"b-tab-table active "+(enableScroll?'scroll-auto ht-400':'')}>
	          <div className="p-b-15 m-b-15 m-t-15">
	          	<FormFieldCard formFields={formFields} 
	          		dataSource={activity.data_source || {}}
	          		data={activity.data || {}}
	          		formReviewProps={formReviewProps}
	          		currentLocale={currentUser.current_locale}/>
	          	{componentName === 'growth_measurement' &&
	          		<GrowthMeasure a={activity} data={activity.data} 
	          			ds={activity.data_source} child={child}
	          			isReviewEnabled={isReviewEnabled}
	          			reviewByClientId={reviewByClientId}
	          			showReviewForm={showReviewForm}/>
	          	}
	          </div>
	        </div>
	      </div>
	      <Footer {...{sid, caseId, caseName, childName, activity, currentUser, routeActivityForm, toggleModal, deleteConfirm, showWhatsapp, whatsAppPhoneNo}}/>
	    </div>
	  </div>
  )
}

const DuplicateActivityAlert = ({caseId, sid, formFields, activity, child, componentName, caseName, childName, currentUser, enableScroll=true}) =>{
	useStyle('/css/tile_components.css', true);

	return (
	  <div id="ct">
	    <div className="bg-white brd-10 card-shadow m-b-15">
	      <div className="white bg-red-3 brd-10 flex p-b-15 p20 coldir">
			    <h5 className="fw700 f18 card-heading flex coldir text-center">
			 {caseName} - You have already submitted this form for same date.Do not submit a duplicate form
			    </h5>
			    <div className="f14 m-t-10 fw500">
			      {Cs.formatUpdateDate(activity.updated_at)}
			      <DraftBadge isDraft={activity.draft}/>
			    </div>
			  </div>
	      <div className="card-content p20">
	        <div className={"b-tab-table active "+(enableScroll?'scroll-auto ht-400':'')}>
	          <div className="p-b-15 m-b-15 m-t-15">
	          	<FormFieldCard formFields={formFields} 
	          		dataSource={activity.data_source || {}}
	          		data={activity.data || {}}/>
	          	{componentName === 'growth_measurement' &&
	          		<GrowthMeasure a={activity} data={activity.data} 
	          			ds={activity.data_source} child={child}/>
	          	}
	          </div>
	        </div>
	      </div>
	      <Footer {...{sid, caseId, caseName, childName, activity, currentUser}}/>
	    </div>
	  </div>
  )
}

const ActivityCardPage = (props) =>{
	const {currentUser} = useCurrentUserHook();
	const [activity, setActivity] = useState();
	const [child, setChild] = useState();
	const [formFields, setFormFields] = useState();
	const [formFieldsGrouped, setFormFieldsGrouped] = useState({});
	const [loading, setLoading] = useState(true);
	
	const params = props.match.params;
	const queryParam = querystringify.parse(props.location?.search);
	const caseName = queryParam?.cn ? Cs.b64DecodeUnicode(queryParam?.cn) : props.caseName;

	useEffect(()=>{
		getActivity();
	}, [])

	const getActivity = async () => {
		const {status, data} = await ActivityService.getById(
			{
				'id':params.id, 
				'fields':['child'],
				'add_form_fields': true,
      	'journey_profile_id':params.journey_profile_id
			}
		)
		if(status === 200){
			setChild(data.child)
			setActivity(data.activity)
			setFormFields(data.form_fields[data.activity.activity_form_id])
			setFormFieldsGrouped(Cs.listToObj(data.form_fields[data.activity.activity_form_id], 'client_id'))
			setLoading(false)
		}
	}

	if(loading)return <Spinner/>;

	if(activity.activity_type === 'assess_bf'){
		return(
			<CTLazyLoad css={['/css/tile_components.css']}>
				<div className="m-t-50">
					<AccessBFCard activity={activity} formFields={formFields}
					componentName={activity.activity_type} caseName={caseName} scrollEnabled={false}
					showWhatsapp={false} enableScroll={false} currentUser={currentUser}
					isReviewEnabled={props.isReviewEnabled}/>
				</div>
			</CTLazyLoad>
		)
	}

	return(
		<div className="m-t-45">
			<ActivityCard formFields={formFields} activity={activity} child={child} 
			currentUser={currentUser} componentName={activity.activity_type} 
			caseName={caseName} showWhatsapp={false} enableScroll={false}
			isReviewEnabled={props.isReviewEnabled}/>
		</div>
	)

}

export {GrowthMeasure, ActivityCard, DuplicateActivityAlert, ActivityCardPage, AccessBFCard, BFPanelList};