import env from '../env';
import axios from 'axios';
import moment from '../../node_modules/moment/min/moment.min.js';

class CommonService {

  static useAxios(req){
    return new Promise((resolve, reject) => {
      axios(req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  /*static getDataSources(req){
    return new Promise((resolve, reject) => {
      axios.get(env.ecn_backend_api+'data_sources', {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }*/

  static getFileUrl(file, isDownload=true){
    return env.file_url+file+(isDownload?'&is_download=1':''); 
  } 

 	static getIconByType(file, type, pre_url){ 
    if(file){
      if(file.match(/\.(jpeg|jpg|gif|png|ico|svg|webp|avif|heif|heic)$/) != null){
        if(file.includes('selftree-public')){
          return file;
        }else{
          return env.file_url+file; 
        }
      }else if(file.match(/\.(pdf|PDF)$/)){
        return "/images/pdf_icon.png";
      }else if(file.match(/\.(xls|XLXS|XLS|xlsx|ods)$/) != null){
        return "/images/excel_icon.png";
      }else if(file.match(/\.(doc|docx|odt)$/)){
        return "/images/doc_icon.jpg";
      }else if(file.match(/\.(ppt|pptx|odp)$/)){
        return "/images/ppt.jpg";
      }else if(file.lastIndexOf(".csv")>0){
        return "/images/csv.jpg";
      }else if(file.lastIndexOf(".key")>0){
        return "/images/key.png";
      }else if(file.lastIndexOf(".wav")>0){
        return "far fa-file-audio"
      }else{
        return "/images/attachement-64.png";
      }
    }
  }

  static trimTitle(title){
    return title.length > 30 ? (title.substring(0,36) + '...'):title;
  }

	static getFileName(url){
    return url?url.substring((url.lastIndexOf('/'))+1):'';
  }
  
  /*static removeUnusedCss(){
    document.head.querySelectorAll(`link[css-remove="true"]`).forEach(function(a){
      a.remove()
    })    
    //document.querySelectorAll('[rel="stylesheet"]');
  }*/

  /*static loadCss(file_name){
    const link = document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', `/styles/${file_name}.css`);
    link.setAttribute('css-remove', "true");
    document.head.appendChild(link);
  }*/

  static formatUpdateDate(date, format){
    if(date)
      return moment(date).format(format || 'MMM D, YYYY');
  }

  static compareDate(date1, date2, return_type='days', non_negative=false, add_decimal=false){
    const time1 = date2?moment(date2):moment();
    const time2 = moment(date1);

    return  non_negative ? 
      time1.diff(time2, return_type, add_decimal) : time2.diff(time1, return_type, add_decimal);
  }
  
  static isToday(date){
    return moment().isSame(date, 'day');
  }

  static getCurrentDateTime(format=null){
    return moment().format(format);
  }

  static geTimeDiff(end, start){
    const ms = moment(end).diff(moment(start));
    const d = moment.duration(ms)._data;
    return `${d.hours}:${d.minutes}:${d.seconds}`;
  }

  static stringToDate(date, addTime=false){
    if(date){
      const s = date.split(/[^0-9]/);
      if(addTime && s[3] && s[4]){
        return new Date (s[0],s[1]-1,s[2],s[3],s[4], (s[5] || '00'))
      }

      return new Date (s[0],s[1]-1,s[2], '23', '59', '59');
      //for (i=0;i<a.length;i++) { alert(a[i]); }
      //new Date (s[0],s[1]-1,s[2],s[3],s[4],s[5])
      //endDate is considering the 0:00:00 time will display one day before so add 23 59 59
    }
  }

  static calcDate(date1, date2) {
    let mdate1 = moment(date1);
    const mdate2 = moment(date2);

    const totalYears = mdate2.diff(mdate1, 'years')
    const totalDays = mdate2.diff(mdate1, 'days')
    if(totalYears > 0)
      mdate1 = mdate1.add('years', totalYears) 
    
    const monthsPassed = mdate2.diff(mdate1, 'months')
    if(monthsPassed > 0)
      mdate1 = mdate1.add('months', monthsPassed) 
    
    const weeksPassed = mdate2.diff(mdate1, 'weeks')
    if(weeksPassed > 0)
      mdate1 = mdate1.add('weeks', weeksPassed) 

    const daysPassed = mdate2.diff(mdate1, 'days')

    return {
      "totalDays":totalDays,
      "days":daysPassed,
      "years":totalYears,
      "months":monthsPassed,
      "weeks":weeksPassed,
    }

    /*
    * ref - https://blog.bitsrc.io/calculate-the-difference-between-two-2-dates-e1d76737c05a
    * calcDate() : Calculates the difference between two dates
    * @date1 : "First Date in the format MM-DD-YYYY"
    * @date2 : "Second Date in the format MM-DD-YYYY"
    * return : Object
    */
  }

  static getAge(d1, d2, add_decimal=false, fraction_size=0, dwmy='month'){
    if(d1){
      d2 = d2 || new Date();
      const r = moment(d2).diff(moment(this.stringToDate(d1)), dwmy, add_decimal); 
      if(add_decimal && fraction_size){
        return r.toFixed(fraction_size);
      }
      return r;
    }
  }

  static addDwmyToDate(date, dwmy, no, dateFormat, toDate){
    const d = moment(date).add(no, dwmy);
    if(dateFormat){
      return d.format(dateFormat);
    }else if(toDate){
      return d.toDate();
    }
    return d;
  }

  /*
    inclusivity 
    moment('2016-10-30').isBetween('2016-10-30', '2016-12-30', null, '()'); //false
    moment('2016-10-30').isBetween('2016-10-30', '2016-12-30', null, '[)'); //true
    moment('2016-10-30').isBetween('2016-01-01', '2016-10-30', null, '()'); //false
    moment('2016-10-30').isBetween('2016-01-01', '2016-10-30', null, '(]'); //true
    moment('2016-10-30').isBetween('2016-10-30', '2016-10-30', null, '[]'); //true
  */
  static isDateTimeBetween(start, end, dateTime, format, inclusivity='[]'){
    if (start && end) {
      const date = moment(dateTime, format);
      const startTime = moment(start, format);
      const endTime = moment(end, format);

      if (date.isBetween(startTime, endTime, null, inclusivity)) {
        return true;
      }else{
        return false
      }
    }
  }

  static ageDWMY(date1, date2){
    const babyAge = this.calcDate(date1, date2);
    let age_ago = babyAge.days +' days';

    if(babyAge.totalDays > 6 && babyAge.weeks > 0){
      age_ago = `${babyAge.weeks}W ${babyAge.days}D`;
    }
    
    //(feb 28)  
    if(babyAge.totalDays > 27 && babyAge.months > 0){  
      age_ago = `${babyAge.months}M ${age_ago}`;
    }

    if(babyAge.totalDays > 364 && babyAge.years > 0){  
      age_ago = `${babyAge.years}Y ${age_ago}`;
    }

    return age_ago;
  }

  static extractHostName(url){
    if(url && url.length>0){
      let u = document.createElement('a');
      u.href = url;
      return u.hostname;
    }
    return '';
  }
 
  static groupBy(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  static b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
     return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
     }));
  }

  static b64DecodeUnicode(str) {
     // Going backwards: from bytestream, to percent-encoding, to original string.
     return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
     }).join(''));
  }

  static subString(str){
    return str && str.substring(0,200)
  }

  static routeJourney(journeyProfile, props){
    if(journeyProfile.data.health_condition == 1){
      /*Cancer journey template*/
      //props.history.push(`/cancer/page/${journeyProfile.id}`)
      props.history.push({
        pathname:`/health/common/page/${journeyProfile.id}`,
        search:'?type=cancer_journey', 
      })
    }else if(journeyProfile.data.health_condition == 2){
      /*Maternal/Infant Health (Advanced) journey*/
      //props.history.push(`/infant/page/${journeyProfile.id}?type='infant_journey'`)
      props.history.push({
        pathname:`/health/common/page/${journeyProfile.id}`,
        search:'?type=infant_journey', 
      })
    }else if(journeyProfile.data.health_condition == 3){
      /*Infant Nutrition and Growth Assessment (Base) journey*/
      //props.history.push(`/infant/page/${journeyProfile.id}?type='infant_journey'`)
      let qp = `?type=infant_journey`
      if(journeyProfile?.child?.id)qp += `&child_id=${journeyProfile?.child?.id}`
      props.history.push({
        pathname:`/health/common/page/${journeyProfile.id}`,
        search:qp, 
      })
    }else if(journeyProfile.data.health_condition == 5){
      /*Dementia journey*/
      props.history.push({
        pathname:`/health/common/page/${journeyProfile.id}`,
        search:'?type=dementia_journey', 
      })
    }
    else if(journeyProfile.data.health_condition == 7){
      /*CKD journey*/
      props.history.push({
        pathname:`/health/common/page/${journeyProfile.id}`,
        search:'?type=ckd_journey', 
      })
    }
    else if(journeyProfile.data.health_condition == 12){
      /*Adult Metabolic journey*/
      props.history.push({
        pathname:`/health/common/page/${journeyProfile.id}`,
        search:'?type=adult_metabolic', 
      })
    }else if(journeyProfile.journey_type){
      props.history.push({
        pathname:`/health/common/page/${journeyProfile.id}`,
        search:'?type='+journeyProfile.journey_type, 
      })
    }
  }

  static routeJourneyForm(ds, history, dataCollectedBy, goBack){
    let qs = `?type=${ds.value}&journey_type=${ds.sid}`
    if(dataCollectedBy)qs += `&data_collected_by=${dataCollectedBy}`
    if(goBack)qs += `&go_back=${goBack}`

    history.push({
      pathname:`/health/form/${ds.child_form}`,
      search:qs, 
      state:{'health_condition': ds.value}
    })
  }

  static numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  static linkify(inputText) {
    try{
      let replacedText, replacePattern1, replacePattern2, replacePattern3;

      //URLs starting with http://, https://, or ftp://
      replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
      replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

      //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
      replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
      replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

      //Change email addresses to mailto:: links.
      replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
      replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

      return replacedText;
    }catch(e){
      return inputText;
    }
  }

  static listToObj(list, key){
    return list ? list.reduce((obj, item) => (obj[item[key]] = item, obj) ,{}) : {};
  }

  static listToObjData(list, key){
    return list ? list.reduce((obj, item) => (obj[item.data[key]] = item, obj) ,{}) : {};
  }

  static getFormAssessmentFields(req){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'training/assessment/form', {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    }) 
  }

  static listToObj(list, key){
    return list && list.reduce((obj, item) => (obj[item[key]] = item, obj) ,{});
  }

  static routeMedicalFacility(organization_id){
    return {
      isPopupMode:true,
      match:{
        params:{
          organization_id
        }
      },
      location:{
        search: ``
      }
    }
  }

  static routeOrgMemberList(organization_id){
    return {
      isPopupMode:true,
      match:{
        params:{
          organization_id,
        }
      },
      location:{
        search: ``
      }
    }
  }

  static routeCancerPath(path, journey, history, id){
    if(['symptoms', 'daily_check', 'pain_tracking', 'side_effects', 'care_giver', 'health_center'].includes(path.sid)){
      return {
        match:{
          params:{
            journey_profile_id: journey.id,
            id: id
          }
        },
        location:{
          search: `?form_id=${path.child_form}&label=${path.sid}`
        }
      }
    }if(['assess_sleep', 'assess_stress', 'diet_recall', 'physical_activity'].includes(path.sid)){
      return {
        match:{
          params:{
            journey_profile_id: journey.id,
            id: id
          }
        },
        location:{
          search: `?form_id=${path.child_form}&label=${path.sid}`
        }
      }
    }else if(['family_member'].includes(path.sid)){
      return {
        match:{
          params:{
            id: id
          }
        },
        location:{
          search: `?form_id=${path.child_form}&label=${path.sid}`
        }
      }
    }
  }

  static routeInfantPath(path, child, journey, history, parent_activity_id, activity_id){
    if(path.sid === 'guides'){
      history.push(`/health/planner/reel/${journey.id}?planner_form_id=476&label=${path.label}`, {journey, child})
    }else if(path.sid === 'calendar'){
      history.push(`/infant/schedules/${journey.id}?label=${path.label}`, {journey, child})
    }else if(path.sid === 'wellness'){
      history.push(`/health/chart/${journey.id}?label=${path.label}`, {journey, child})
    }else if(path.sid === 'baby'){
      //c.data.baby_age = Cs.getAge(c.data.dob_baby_formatted_date)
      const childData = {
        id_mother: journey.data?.cell_number, 
      }
      history.push(`/infant/child/form/${journey.id}/${child.id ?? ''}?label=${path.label}`, childData)
    }else if(path.sid === 'track'){
      history.push(`/health/path/${journey.id}?label=${path.label}`, {journey, child});
    }else if(path.sid === 'visits'){
      history.push(`/infant/activities/${journey.id}?activity_form_id=483&label=${path.label}`, {journey, child})
    }else if(path.sid === 'bfmethod'){
      history.push(`/infant/activities/${journey.id}?activity_form_id=486&label=${path.label}`, {journey, child})
    }else if(path.sid === 'instant_baby' || path.sid === 'child_birth'){
      let qp = '?label=child'
      if(journey.associated_journey_id){
        qp += `&associated_journey_id=${journey.associated_journey_id}`
      }
      if(path.is_form_wizard === 'yes')qp += '&is_form_wizard=1'; //render as form wizard
      if(path.child_form)qp += `&form_id=${path.child_form}`; //render as form wizard
      return {
        match:{
          params:{
            journey_profile_id: journey.id
          }
        },
        location:{
          search: qp,
          state:{id_mother: journey.data?.cell_number}
        }
      }
    }else if(path.sid === 'Counseling'){
      history.push(`/infant/list/counseling/reel/${journey.id}?label=${path.label}`, {journey, child})
    }else if(['birth_history', 'prior_services', 'household_details', 'addnal_info', 'household_info', 'family_details', 'adolescent', 'registration', 'mother_edu_occupation'].includes(path.sid)){
      return {
        match:{
          params:{
            journey_profile_id: journey.id,
            id: activity_id
          }
        },
        location:{
          state:{id_mother: journey.data?.cell_number},
          search: `?form_id=${path.child_form}&label=${path.sid}&sid=${path.sid}`
        }
      }
    }else if(['growth_measurement', 'feeding_assessment', 'assess_cf', 'assess_bf', 'prenatal_care', 'periodic_check', 'protein_intake', 'immunization'].includes(path.sid)){
      let qp = `?activity_form_id=${path.child_form}&label=${path.sid}&sid=${path.sid}`;
      if(path.is_form_wizard === 'yes')qp += '&is_form_wizard=1'; //render as form wizard
      return {
        match:{
          params:{
            journey_profile_id: journey.id,
            id: activity_id
          }
        },
        location:{
          state:{},
          search:qp 
        }
      }
    }else if(path.sid === 'case_assessment'){
      history.push(`/case/${journey.id}/assessments/${journey.created_by}`)
    }else{
      return {
        match:{
          params:{
            journey_profile_id: journey.id,
            id: activity_id
          }
        },
        location:{
          search: `?form_id=${path.child_form}&label=${path.sid}&sid=${path.sid}`
        }
      }
    }
  }

  static getCardCount(screen){
    if(screen.xs){
      return 1
    }else if(screen.sm){
      return 2
    }else if(screen.md){
      return 3
    }else if(screen.lg){
      return 3
    }
  }

  static emailStatus(item){
    if(!item.is_active){
      return 'InActive'
    }
    if(item.user?.current_sign_in_at){
      return 'Login'
    }
    if(item.joined_from_invite){
      return 'Joined'
    }
    if(item.is_link_visited){
      return 'Invitation opened'
    }
    if(item.report?.app_status){
      //app_download_status
      return item.report?.app_status
    }
    if(item.is_email_sent){
      return 'Invitation sent'
    }
  }
  
  static displayRandomPhrase(i){
    let r_text = [];
    r_text[0] = "From screening to extended care"
    r_text[1] = "Support community and individual beneficiary needs"
    r_text[2] = "Leverage custom digital capabilities"
    r_text[3] = "Provide field staff with powerful tools"
    r_text[3] = "Augment trained staff capacity in the field"
    r_text[3] = "Mentor staff, not monitor"
    r_text[3] = "Translate insights into measurable outcomes"
    r_text[3] = "Get actionable health and well-being insights"
    return r_text[i]
  }
  
  static round(value){
    return value?Math.round(value):null;
  }

  static getSelectedText() {
    let text = "";
    if (typeof window.getSelection != "undefined") {
        text = window.getSelection().toString();
    } else if (
        typeof document.selection != "undefined" &&
        document.selection.type == "Text"
    ) {
        text = document.selection.createRange().text;
    }
    return text;
  }

  static stripeHtml(text){
    return text?.replace(/(<([^>]+)>)/gi, "")
  }

  static getSeconds(time){
    try{
      if(time){
        const a = time.split(':')
        a.length<3 && a.unshift(0);
        return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2])
      }
    }catch(e){
      console.log(e.message)
    }
  }

  static getBrowserType(){
    const ua = navigator.userAgent;
    if((ua.indexOf("Opera") || ua.indexOf('OPR')) != -1 ){
      return 'Opera';
    }else if(ua.indexOf("Chrome") != -1 ){
      return 'Chrome';
    }else if(ua.indexOf("Safari") != -1){
      return 'Safari';
    }else if(ua.indexOf("Firefox") != -1 ) {
      return 'Firefox';
    }else if((ua.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) //IF IE > 10
    {
      return 'IE'; 
    }
  }  
  
  static formFieldListToObj = (formsMap={}) =>{
    for (const f in formsMap){
      formsMap[f] = this.listToObj(formsMap[f], 'client_id')
    }
    return formsMap
  }

  static searchList = (list, searchKeyword) =>{
    return list.filter(o => JSON.stringify(o)?.toLowerCase()?.indexOf(searchKeyword) > -1);
  }

  static nestedListSearch = (list, searchKeyword) =>{
    const result = this.searchList(list, searchKeyword)
    for(const o of result){
      for (const [k, v] of Object.entries(o)) {
        if(Array.isArray(v)){
          o[k] = this.searchList(v, searchKeyword)
        }
      } 
    }
    return result
    //return list.filter(o => JSON.stringify(o)?.toLowerCase()?.indexOf(searchKeyword) > -1);
  }

  static deepCopy = (object) =>{
    return JSON.parse(JSON.stringify(object))
  }

  static setSessionStore(key, value, isJson){
    sessionStorage.setItem(key, (isJson?JSON.stringify(value):value))
  }

  static getSessionStore(key, isJson){
    let d = sessionStorage.getItem(key)
    return d && isJson?JSON.parse(d):null 
  }

  static getCookie(name) {
    const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith(name))
    ?.split("=")[1];
    return cookieValue
  }

  static getUniqueListByKeys = (arr, keys=[]) =>{
    return arr.filter((v,i,a)=>a.findIndex(v2=>keys.every(k=>v2[k] ===v[k]))===i)
  }
  
  //Remove in future download from backend
  static jsonToCsv = (data) =>{
    const headers = Object.keys(data[0])
    const seperator = ",";
    const csv = [
        headers.map(h => `"${h}"`).join(seperator),

        ...data.map(row => headers.map(field => {
            if(typeof row[field] === "object"){
                return ""
                //${JSON.stringify(row[field])}
            }

            return `"${row[field]}"`
        }).join(seperator))
    ]

    return csv.join("\n")
  }

  static extractUrlFromRichTxt = (richText) =>{
    if(richText){
      const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
      return richText.match(urlRegex) || [];
    }
    return []
  }

  static objCountNonNull(obj){
    return Object.keys(obj).filter(k => (obj[k]?.toString()?.length > 0))?.length;
  }

  static calcMultiColAverage(data, columns) {
    const averages = {};
    columns.forEach((column) => {
      averages[column] = (data.reduce((sum, row) => sum + (row[column] || 0), 0) / data.length).toFixed(2);
    });

    return averages;
  }
  
  static calcMultiColTotal(data, columns) {
    const totals = {};
    columns.forEach((column) => {
      totals[column] = data.reduce((sum, row) => sum + (row[column] || 0), 0);
    });
  
    return totals;
  }
static calcAssessmentTotals(reports, assessmentLabels) {
  const assessmentTotals = assessmentLabels.reduce((acc, label) => {
    acc[label] = {
      answer_count: reports.reduce((sum, org) => 
        sum + (org.assessments.find(a => a.quiz_label === label)?.answer_count || 0), 0),
      examinee_count: reports.reduce((sum, org) => 
        sum + (org.assessments.find(a => a.quiz_label === label)?.examinee_count || 0), 0),
    };
    return acc;
  }, {});

  return assessmentTotals;
}
  /*static nextUid() {
    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTime
    const dateObject = new Date();
    const uniqueId = 
      dateObject.getFullYear() + '' + 
      dateObject.getMonth() + '' + 
      dateObject.getDate() + '/' + 
      dateObject.getTime();
    return uniqueId.toString();
  }*/

}

export default CommonService;