import React, { useState, useEffect } from 'react'
import { CueTip } from '../../../components/Common/CueTooltip'
import FormHelper from '../../../components/FormBuilder/FormHelpers'
/*import useCustomForm from '../../../hooks/useCustomForm';*/
import { FieldLabel, Description, RequiredMsg, FieldReview, FieldPoint } from '../FieldLabel'
import NumberFormat from 'react-number-format'

const InputTextField = ({renderField, field, formData, forceUpdate, formFn, readOnly, errors, isFormWizard, position, openFieldLabelModal, currentLocale, labelLocale, formId, fieldReview}) => {

  const [showError, setShowError] = useState(false);
  const [value, setValue] = useState(formData[field.client_id]);
  const [showDomain, setDomain] = useState(false); //for input email

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':readOnly,
    'type':'text',
    'required':field.required,
    'className':'form-input form-input-gray form-input-full'
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let validation = {};
  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  FormHelper.setInputTypeValidation(field, inputAttributes, validation, position, formFn, formData);
  FormHelper.setMinMaxValidation(field, inputAttributes, null);
  //FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);
  
  useEffect(()=>{
    if(value != formData[field.client_id]){
      formData[field.client_id] = FormHelper.checkDecimalPoint(formData[field.client_id], inputAttributes.fraction_size)
      setValue(formData[field.client_id])
    }
  }, [formData[field.client_id]])

  if(!renderField){
    return null
  }

  formFn["update_"+field.client_id] = (value) => {
    const ele = document.getElementById(field.client_id);
    ele.value = value;
    handleChange({'target':ele});
  };

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    formData[name] = (isNaN(target.valueAsNumber) ?  target.value : target.valueAsNumber);
    setValue(formData[name]);
    FormHelper.setFormTitle(field, formData, target.value);
    //FormHelper.checkCustomValidation(field, formData, null, errors[field.client_id]);
    if(field.required || target.value?.length > 0){
      setError(event);
    }else{
      //not required empty fields validation true
      FormHelper.setErrorState(errors, name, false);
      setShowError(!showError);
    }

    //show domain list
    if(field.component_type === "email"){
      setDomain(true);
    }
  }

  const handleDomainChange = (domain) =>{
    if(domain){
      const ele = document.getElementById(field.client_id);
      const splits = ele.value.split('@');
      ele.value = splits[0]+'@'+domain;
      handleChange({'target':ele});
      setDomain(false);
    }
  }

  const setFormatNumber = (values, event) =>{
    const { formattedValue, value } = values;
    formData[field.client_id] = value;
    formData[field.client_id+'_formatted'] = formattedValue;
    setValue(value);
    FormHelper.setFormTitle(field, formData, formattedValue);
    if(field.required)
      //Only required field validation
      setError(event);
  }
    
  const setError = (event) => {
      errors[field.client_id].touched = true;
      errors[field.client_id].err_msg =  null;
      FormHelper.checkInputValid(field, event.target, errors[field.client_id]); //check this validation before line 99
      const { target:{name, value, max, maxLength, min, minLength, validity:{valid, valueMissing, typeMismatch, patternMismatch, tooShort, tooLong, rangeOverflow, rangeUnderflow}} } = event;
      errors[name].invalid = !valid;
      /*errors[name].typeMismatch = typeMismatch;
      errors[name].patternMismatch = patternMismatch;
      errors[name].tooShort = tooShort;
      errors[name].tooLong = tooLong;
      errors[name].rangeOverflow = rangeOverflow;
      errors[name].rangeUnderflow = rangeUnderflow;*/

      if(errors[name].invalid){
        errors.invalid[name] = true;
        if(valueMissing){
          errors[name].err_msg =  field.label+' '+labelLocale(1);
        }else if(typeMismatch){
          errors[name].err_msg = labelLocale(18);
        }else if(patternMismatch){
          errors[name].err_msg = validation.patternMessage || labelLocale(18);
        }else if(tooShort){
          errors[name].err_msg = labelLocale(23);
        }else if(tooLong){
          errors[name].err_msg = labelLocale(24);
        }else if(rangeOverflow){
          errors[name].err_msg = labelLocale(21);
          //'Input must be lower than or equal to ' + max;
        }else if(rangeUnderflow){
          errors[name].err_msg = labelLocale(21);
          //'Input must be greater than or equal to ' + min;
        }
      }else if(field.component_type === "phone_no" && value.includes("_")){
        errors.invalid[name] = true;
        errors[name].err_msg =  field.label+' '+labelLocale(1);
      }else{
        delete errors.invalid[name];
      }

      if(formFn['set_error_'+field.client_id]){
        formFn['set_error_'+field.client_id](errors);
      }
      setShowError(!showError);
  }

  const refreshFormSubmit = () =>{
    if(typeof formData[field.client_id] === 'string')formData[field.client_id] = formData[field.client_id]?.trim();
    formFn.refreshFormSubmit();
  }

  const Error = () => {
    if(errors[field.client_id].invalid && (errors[field.client_id].touched || errors.isSubmitted)){
      return(
        <div className="errormsg">
          <span className="fas fa-exclamation-triangle m-r-5"/>
          {errors[field.client_id].err_msg || <RequiredMsg {...{field, currentLocale}}/>}
        </div>
      )
    }else if(errors[field.client_id]?.err_msg){
      return(
        <div className="errormsg">
          {errors[field.client_id].err_msg}
        </div>
      )
    }
    return null
  }

  if(field.component_type === "phone_no" || field.component_type === "number_pattern"){
    return(
      <div {...parentAttributes}>
        <div className="flex coldir m-b-20 tooltip">
          <CueTip 
            positionCss={position>1?'top':'bottom'}
            tooltip={field.tooltip}
            currentLocale={currentLocale}
            locale={field.locale?.tooltip}/>
          <FieldLabel field={field} 
            isFormWizard={isFormWizard}
            labelAttributes={labelAttributes} 
            currentLocale={currentLocale}
            openFieldLabelModal={openFieldLabelModal}/>
          <div className="flex">
            {field.component_type === "phone_no" 
              ? <input 
                defaultValue={formFn.country_code}
                className="form-control w-42 ht-42 f15"
                placeholder="Country Code" />
              : null
            }
            <NumberFormat mask="_" value={value} {...inputAttributes}
              onValueChange={(values, sourceInfo) => setFormatNumber(values, sourceInfo.event)} 
              onBlur={() => refreshFormSubmit()}
              data-tip data-for={`tooltip_${field.client_id}`}/>
          </div>
          <FieldPoint userRefId={formFn.userRefId} clientId={field.client_id} 
            point={field.point} currentLocale={currentLocale} 
            negPoint={field.incorrect_point}/>
          <Description description={field.description} 
            currentLocale={currentLocale}
            locale={field.locale?.description}/>
          <FieldReview review={fieldReview}/>
          <Error/>
        </div>
      </div> 
    )
  }else if(field.component_type === "email"){
    const domains = ['gmail.com', 'yahoo.com', 'cuetree.com']
    const inputdomain = value?.split('@')?.[1] || ''

    return(
      <div {...parentAttributes}>
        <div className="flex coldir m-b-20 tooltip">
          <CueTip 
            positionCss={position>1?'top':'bottom'}
            tooltip={field.tooltip}
            currentLocale={currentLocale}
            locale={field.locale?.tooltip}/>
          <FieldLabel field={field} 
            isFormWizard={isFormWizard}
            labelAttributes={labelAttributes} 
            currentLocale={currentLocale} 
            openFieldLabelModal={openFieldLabelModal} 
            formId={formId}/>
          <input {...inputAttributes} list="domains" onChange={e => handleChange(e)} 
            value={value} onBlur={(e) => refreshFormSubmit(e.target)}
            autoComplete="off"/> 
          {(showDomain && inputdomain) ?
            <div className="autocomplete-items">
              {domains.map((d, i) => d.includes(inputdomain) ?
                <div key={i} onClick={()=>handleDomainChange(d)}>{value.split('@')[0]+'@'+d}</div>
                :
                null
              )}
            </div>
            :
            null
          }
          <Description description={field.description} currentLocale={currentLocale}
            locale={field.locale?.description}/>
          <Error/>
        </div>
      </div> 
    )
  }else{
    return (
      <div {...parentAttributes}>
        <div className="flex coldir m-b-20 tooltip">
          <CueTip 
            positionCss={position>1?'top':'bottom'}
            tooltip={field.tooltip}
            currentLocale={currentLocale}
            locale={field.locale?.tooltip}/>
          <FieldLabel field={field} 
            isFormWizard={isFormWizard}
            labelAttributes={labelAttributes} 
            currentLocale={currentLocale}
            openFieldLabelModal={openFieldLabelModal} formId={formId}/>
          <input {...inputAttributes}
            onChange={e => handleChange(e)} 
            value={value} 
            onBlur={(e) => {refreshFormSubmit(e.target);forceUpdate()}}/> 
          <Description description={field.description} currentLocale={currentLocale}
            locale={field.locale?.description}/>
          <Error/>
        </div>
      </div> 
    )
  }
}

export default InputTextField;