import React from "react";
import { useMemo } from "react";
//import ActivityMenuModal from "../MotherInfantJourney/Activities/List/ActivityMenuModal"
import { PhoneIcon, MapIcon, LinkMapIcon, LinkMailIcon, LinkUserIcon, LinkDeleteIcon, LinkFontAwesomeIcon } from "../../Common/ImageIcons";
import Cs from '../../../services/CommonService';
import { NotificationBadge } from "../../Common/BadgePill";
import { Path, MultiplePregnancy, WeightZscorePill, StatuDropDown, RecordId, HighRiskPregnancy } from './Common';
import { UserName } from '../../UserProfile/UserInfoPopup';
import { useActivityHook } from "../MotherInfantJourney/Activities/List/useActivityHook";
import Rating from '../../Common/Rating';
import { MultiReviewScore } from "../../Reviews/Common";
import { DropMenu, DropMenuItem } from "../../Common/MenuItem";

const MobileRequired = ({labelLocale}) => <font className="c-bemedian">{labelLocale(13)}</font>;
const BabyNameRequired = ({labelLocale}) => <font className="c-bemedian">{labelLocale(12)}</font>;
const AddressRequired = ({labelLocale}) => <font className="c-bemedian">Address Not Provided</font>;

const Infant = (props) => {
  let {journey, permission, dataSources, currentUser, onDelete, undoDelete, openUserRole, routeJourney, openNotification, index, update, pregnancyStageKv, labelLocale, isAssessmentEnabled, openFormSubmissionReview, openCaseAssessment} = props;
  let {data, data_source:ds} = journey;
  let child = journey.child || {data:{}};
  
  const {ageFormat} = useActivityHook(child);
  const babyAgeAgo = useMemo(() => ageFormat(null, Cs.getCurrentDateTime()), [journey.id]);

  const addressInfo = (data.taluka || data.address || '') +' '+ (data.village || '') +' '+ (data.awc_name_number || '');
  const mobileNo = (data.cell_number_formatted || data.cell_number);
  
  return(
    <div className="bg-white brd-10 card-shadow m-b-15">
      <div className="card-content p-15">
        <div className="scroll-auto ht-165">
          <div className="flex justspacebetween">
            <h4 className="flex cgap-10 aligncenter text-left">
              <span className="f18">{journey.record_id}</span>
              <WeightZscorePill j={journey} />
              <span>{babyAgeAgo}</span>
              <HighRiskPregnancy isHighRisk={data.is_high_risk_pregnancy} />
            </h4>
            <StatuDropDown id={journey.id} currentLocale={currentUser.current_locale}
              status={journey.status} statusReason={journey.status_reason} {...{ update, index }} />
          </div>
          <div className="flex justspacebetween aligncenter cgap-10 f12 text-gray w-full">
            <div>Mother name - Child name</div>
            <div>Created on:</div>
          </div>
          <span className="flex justspacebetween cgap-10 m-b-10 bold w-full">
            <div>
              {data.mother_name || data.patient_name} -
              {child.data.baby_name ? ` ${child.data.baby_name}` : <BabyNameRequired labelLocale={labelLocale} />}
            </div>
            <div>{Cs.formatUpdateDate(journey.last_update_at)}</div>
          </span>
          {/* <WeightZscorePill j={journey}/> */}

          <div className="flex aligncenter m-b-10">
            <div className="flex aligncenter m-r-20">
              <PhoneIcon title={mobileNo || <MobileRequired labelLocale={labelLocale} />} />
            </div>
            <div className="flex aligncenter m-r-20">
              <MapIcon title={addressInfo || <AddressRequired labelLocale={labelLocale} />} />
            </div>
            <div className="flex aligncenter">
              <MultiplePregnancy journey={journey} child={child} />
            </div>
          </div>

          {/* <div className="flex aligncenter m-b-10">
            <div className="flex coldir m-r-20">
              <UserName className="dblock" id={journey.last_update_by} role={journey.member_role}>
                {journey?.last_updated_user?.name}
              </UserName>
              <div>{journey?.member_role?.role}</div>
              <Rating 
                sumOfRating={journey.case_assessment_rating?.data?.rate_case_worker}
                onSelect={()=>openCaseAssessment(journey)}/>
            </div>
            <div className="flex aligncenter m-r-20">
              {Cs.formatUpdateDate(journey.last_update_at)}
            </div>
          </div> */}

          <div className="dblock">
            <Path j={journey} pregnancyStageKv={pregnancyStageKv} currentLocale={currentUser.current_locale} />
          </div>
        </div>
        <div className="m-t-10 flex justspacebetween aligncenter">
          <div className="flex coldir">
            <div className="m-b-2 text-gray f-12">User</div>
            <UserName className="dblock" id={journey.last_update_by} role={journey.member_role}>
              {journey?.last_updated_user?.name}
            </UserName>
            <div>{journey?.member_role?.role}</div>
            <Rating
              sumOfRating={journey.case_assessment_rating?.data?.rate_case_worker}
              onSelect={() => openCaseAssessment(journey)} />
          </div>
          {journey.status !== 'closed' && (
            <div className="flex aligncenter ml-auto gap-10">
              <LinkMapIcon
                id="path_details"
                tooltip={labelLocale(19)}
                onSelect={() => routeJourney(journey)}
                className="m-l-15" 
              >
                <NotificationBadge count={journey.schedule_today_count} />
                {journey.day_since_last_notification > -1 && (
                  <NotificationBadge position="bottom" count={journey.day_since_last_notification} />
                )}
              </LinkMapIcon>

              {journey.nutrition_care_alert_count > 0 && (
                <LinkMailIcon
                  id="notification"
                  count={journey.nutrition_care_alert_count}
                  tooltip={labelLocale(23)}
                  onSelect={() => openNotification(journey)}
                  className="far fa-envelope m-l-10 font-18 text-muted"
                />
              )}

              <DropMenu iconCss="fas fa-solid fa-ellipsis fa-lg font-18 table-icon flex aligncenter justifycenter">
                <DropMenuItem
                  name="Shared Journey"
                  iconCss="fas fa-user-friends"
                  onSelect={() => openUserRole(journey)}
                />

                {permission?.delete && (
                  <DropMenuItem
                    name={journey.is_active ? "Delete Journey" : "Undo Delete"}
                    iconCss="far fa-trash-alt"
                    onSelect={() => (journey.is_active ? onDelete(journey) : undoDelete(journey.id))}
                  />
                )}

                {isAssessmentEnabled && (
                  <DropMenuItem
                    name="Assessments"
                    iconCss="far fa-list-alt"
                    link={`/case/${journey.id}/assessments/${journey.created_by}`}
                  />
                )}

                {journey.child && (
                  <DropMenuItem
                    name="Chart"
                    iconCss="fas fa-chart-line"
                    link={{
                      pathname: `/health/chart/${journey.id}`,
                      state: { journey: journey, child: journey.child },
                    }}
                  />
                )}
              </DropMenu>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Infant;